const CONFIG = {
  "aws_access_key": "f94c8fcd34b63a47ac6d105704fb8b19:cb893f5c0d7129b10bc2932a2056524c7bf903064a8b4417fa1806e670ee3514",
  "aws_secret_key": "dc3ad1db940ede2c690c2e12db4ac3a0:b091350fa19ffc4c3e367c0db29eb30095560a7e5676994427cc3c01ce57ed470c3af0ce78ab614bd4cc1c2d91a6803b",
  "map_key": "f99fcfc3c27c1fd7c230feb5ff887b0e:010efac37ac8397080d77bef31f2ed7f4413873f4b6dc73e0e1dff3434fd235e",
  "airtable_key": "b958d93678fda70c73fb21fd7c68b5d1:4e57e89d3ff05d3115734ab3e7ca6f658b67947709b98b16eef3db55ca3a588ab412b5c9647abe5094f2076f99973c74c15554ce084e8d6a2c209394c6b8dd2422e866821b16ec283da3f0c12387bf46cd4e1f717fb34305c32bbff126c8ce87",
  "aws_region": "552cf4f742bb298f56ad86670ad64533:7d7647092932e1d69a54f48d6dfb65f5",
  "aws_sensors_bucket": "7ab02013c6bdd325e42768d01000da5e:377abddaf7e5663248b3ec92a02f7269886fb06c6ddcec2e9cb5bb8b4124d80e",
  "aws_public_bucket": "d4e971cc709e7c9740e436a4bead5619:41327382ab6205143083d9e6bbe40c18cd2b87294ac047a24ddaf0d290d095db",
  "password_hash": "471100a97f6762512782f78e612e2718:d481a296102f4313dd87145b1a608f94f146bb759a3e6c2cbf8ddf2fbd807a195116e9d67ec3933eea65829a00a23feeec893dce0c68fe27e9f79b79850f9f5d5c72538718b8c740bf7891d674148d04",
  "fitbit_app_id": "358ba8c066490a45e3eda75b99a16412:9803b98631217197234cc4b50ef9d031",
  "sensor_inbox_email": "1d2d89b694d19622254e37baefe2e9af:f0091080af7a728d539ae9a0c86b55c04460a40a8cb57716930916c5cf77eb61",
  "at_app_id": "d6784e495e00cc0463551fac6010dd51:031ea2c85682de19435cbeaf8bc58b024088aa92f99b0cfe66c51eda218883f6",
  "at_sensor_placement_form": "48007f6b218990203d48bcd149a45810:1b06ce5ec7a8054c1e3a97d2170bad420e6502a6a420ef6846086270c33e63cae49f312db0061af64869a3f0dad056a45c3b9d1bf51aad60c99fa2149d4f4228",
  "at_govee_activation_codes_id": "fb994114e921aa893325c99503efa89d:83dded0cb928206b36705537081ae66a5313f12f3a7f7140a1c6f0a80f47877b",
  "at_emails": "3f7ea95920c88975ebf7d355a582aa2b:ddcb857fd35874b63eb00bc29725de545bd4ecbbd2224691026cb3aeebe22144",
  "at_participants": "6d7253ba0820ff2f904e7ef3a23997b4:d90f367bbd5ac6f2a6c1257fa545239b015f5e77940414b535b620c4303c319c",
  "at_phones": "0d2899b480dc17cf11fdae028f8e0c8b:a780bb6abac6429ca4ff72d91eccccaba32f36b7c8c1ed61e69c96e9d4ecac8c",
  "at_phone_placements": "6b40aae666788ce870b15b3712cc406f:31501f5f4fba66eaa0f240404aca9a6231efd74b92b875f2952a7c23f4bed232",
  "at_wearables": "5ab4f6e268f1da9a5bd81ea118514a46:5543394dff71aedf7d4cbee3caa85e936b4faa41c04fc74b91a0e3c9b828dcfe",
  "at_wearable_placements": "bebeda8ca91b1af3610f026b575192d9:37667675e0249da56141e70ab86c3a822154215fa6a1d87cc146cb4228d3634f",
  "at_houses": "fe8bc1748515bcff2680abc0d83408dd:147d9fc35d8df05e8a00673ac66d16b2b6bde47884ce059e8ff0a5ecbcf62d25",
  "at_participant_placement": "a3e57c85f3c56ae254458ce9804dcc6c:af6542f25214407723dba60482618b7ec076bb88cb6bae630ae6c34775ce262b",
  "at_sensors": "1ec66cd91d6d9c64d775e1d0a38031c2:93198e6c00bb2655868684a5b5407bdaabb6ee9e6d1dd59593a9960b78c5be11",
  "at_sensor_house_placement": "8c36e9efe76bd1781c055ded85e20b46:c50ea238073a8ed57606f86e8ae718b435b999e6125b0b4c4e52ed5e5176d39e",
  "at_received_sensor_files": "66d92cf25411ada4af8ff533b9b0109a:5881257087e50bd2e3e4eb4ef5fbee2447c128d4d1c75e11e35483269b45b335",
  "at_bundles": "9a9ddddbd6c1b49d7d1aa94588159016:854df458a643050c496e21482f500c55e35902bcd83870194cc12676c8be26ff",
  "at_issues": "3f65edf636db7d6a24e2bffb76ccc834:5836880db4614b91f6bc25098f1682c9cce6dbc5e3a5811456d2e52fc453aff9",
  "at_perceptual_surveys": "2d889ead62855b3d011ea381be28a23d:1c6c9f5f055947dea6bc8421b9d7478b2060fcce79b906b99eeaedbf25ad5a5b",
  "at_migrations": "48111b6bad47cf725ccc2d38d9582832:1a7054017ebfdbe1b380eedc59d822e7d4aa00fbfc98189bfcb83a6523ffc131",
  "at_collection_records": "6d40440678c54b500578567ea3f3d5aa:1203307985567a38a3edd7155a6f5ddb68f49dacbfb92fa8d2fe3afbcde342db",
  "at_participant_intake_forms": "1f4df36ed7e9e3354efe47a7348552e0:53205a778a5f19ca48f4b5119aad55b3c47191bd076eba571a951b97fd6018ed",
  "at_house_intake_forms": "f1ccdb1d57c389076e36dcddc0622620:3938ea623b4761ae5da77a016182611338e9433f734e0e5d1993b8a8f3bdaf91",
  "at_pending_questions_participant_if": "af1454ba583c49800ab32788eee33fb2:11d4ba23d85349bdb707ffcef9c4ba100b34a89765c57f45ad62ade74487c1c4",
  "at_pending_questions_house_if": "6cf777b34afdfd582fb64ba486f33fb8:dd30757b4236f4436a1399738f561605e7c38b511062c3ad9f5b88d4c71282a8",
  "at_wearable_coverage": "a4b158208ef53c194e9ddd8619319cb6:c97f49a65ee6e31a0a280eeb32420860d28eb8d256ac96cb8addf1e1746a87e1",
  "at_mapped_lab_report_record": "9eddf3d72ab69c9d952bae4361b025ef:028826ff2f6b01bef39f0d004ce4db0ef2be96f840e1b5602348a3ceab0c6fc5",
  "at_received_lab_reports": "ce3d0be598335a8f2bdcee06c52edae3:35bbcad0e96c4e6d49ff6c5a713d8b45192558018f4efae0a9e5ba0203aeb1c4"
} 
export default CONFIG