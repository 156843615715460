import React, { useState } from 'react';
import Title from 'antd/es/typography/Title';
import { downloadZip, extractPDFsFromZip, getMatches, processPdf } from '../community-hats-js-library/utils/labFunctions';

import { Upload, Button, message, Card, Progress, Spin, Input, Space, Table, Tag, Modal } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { RefParticipantsData } from '../hooks/DatasetHooks';
import * as libCon from "../community-hats-js-library/Constants.js"
import { filterObjectByFunction, isNullOrUndefined, isNullOrUndefinedOrEmpty, isNullOrUndefinedOrEmptyOrMissing, sleep, sumArray } from '../community-hats-js-library/utils/generalFunctions.js';
import { exportToExcel, parseExcelFile } from '../community-hats-js-library/utils/ExcelFunctions';
import { format } from 'date-fns';
import { uploadPDFToBucket } from '../community-hats-js-library/utils/AWSFunctions';
import { formatExport, formatToIndiaTimeReadable } from '../community-hats-js-library/utils/dateFunctions.js';
import { LoadingParticipants } from '../components/LoadingDatasets';
import { uploadMapLabReportRecord } from '../community-hats-js-library/utils/airtableFunctions.js';



const GENERAL_COLUMNS = [
    {
        title: libCon.ATF_FILE_NAME,
        dataIndex: libCon.ATF_FILE_NAME,
        align: 'center',
        width: 100,
    },
    {
        title: libCon.ATF_CASE_ID,
        dataIndex: libCon.ATF_CASE_ID,
        align: 'center',
        width: 100,
    },
    {
        title: libCon.ATF_SAMPLE_DATE,
        dataIndex: libCon.ATF_SAMPLE_DATE,
        align: 'center',
        width: 100,
        render: (val) => formatToIndiaTimeReadable(val, true)

    },
    {
        title: libCon.ATF_NAME,
        dataIndex: libCon.ATF_NAME,
        align: 'center',
        width: 100,
    },
    {
        title: libCon.ATF_AGE,
        dataIndex: libCon.ATF_AGE,
        align: 'center',
        width: 100,
    },
    {
        title: libCon.ATF_PHONE_NUMBER,
        dataIndex: libCon.ATF_PHONE_NUMBER,
        align: 'center',
        width: 100,
    },
    {
        title: libCon.ATF_TARGET_COMPLETE_NAME,
        dataIndex: libCon.ATF_TARGET_COMPLETE_NAME,
        align: 'center',
        width: 100,
    },
    {
        title: libCon.ATF_SCORE,
        dataIndex: libCon.ATF_SCORE,
        align: 'center',
        width: 100,
    },
    {
        title: libCon.ATF_NAME_SCORE,
        dataIndex: libCon.ATF_NAME_SCORE,
        align: 'center',
        width: 100,
    },
    {
        title: libCon.ATF_PHONE_SCORE,
        dataIndex: libCon.ATF_PHONE_SCORE,
        align: 'center',
        width: 100,
    },
    {
        title: libCon.ATF_AGE_DIFF,
        dataIndex: libCon.ATF_AGE_DIFF,
        align: 'center',
        width: 100,
    },
    {
        title: libCon.ATF_SCORE_SECOND_BEST,
        dataIndex: libCon.ATF_SCORE_SECOND_BEST,
        align: 'center',
        width: 100,
    },
    {
        title: libCon.ATF_NAME_SCORE_SECOND_BEST,
        dataIndex: libCon.ATF_NAME_SCORE_SECOND_BEST,
        align: 'center',
        width: 100,
    },
    {
        title: libCon.ATF_NAME_SECOND_BEST,
        dataIndex: libCon.ATF_NAME_SECOND_BEST,
        align: 'center',
        width: 100,
    },
    {
        title: libCon.ATF_PHONE_SCORE_SECOND_BEST,
        dataIndex: libCon.ATF_PHONE_SCORE_SECOND_BEST,
        align: 'center',
        width: 100,
    },
    {
        title: libCon.ATF_AGE_DIFF_SECOND_BEST,
        dataIndex: libCon.ATF_AGE_DIFF_SECOND_BEST,
        align: 'center',
        width: 100,
    },
    {
        title: libCon.ATF_TARGET_AGE,
        dataIndex: libCon.ATF_TARGET_AGE,
        align: 'center',
        width: 100,
    },

    {
        title: libCon.ATF_TARGET_PHONE_NUMBER,
        dataIndex: libCon.ATF_TARGET_PHONE_NUMBER,
        align: 'center',
        width: 100,
    },

    {
        title: libCon.ATF_TARGET_SEWA_ID,
        dataIndex: libCon.ATF_TARGET_SEWA_ID,
        align: 'center',
        width: 100,
    }

]


function DownloadFilesButton({ pdfFiles, metadataForFiles, keys, fileName }) {

    const processZip = () => {

        if (!isNullOrUndefinedOrEmptyOrMissing(keys) && !isNullOrUndefined(pdfFiles) & !isNullOrUndefined(metadataForFiles)) {
            let pdfs = keys.map(k => pdfFiles[k])
            let filenames = keys.map(k => metadataForFiles[k][libCon.ATF_FILE_NAME])
            downloadZip(pdfs, filenames, fileName)

        }

    }

    return (
        <Button key="submit" type="primary" onClick={processZip}>
            Download Files
        </Button>
    )


}

function MatchChecker({ metadataForFiles, participants }) {

    const [caseId, setCaseID] = useState(null)

    const [closestMatches, setClosestMatches] = useState([])


    const showResults = async () => {

        setClosestMatches([])

        let finalCaseId = `${caseId}`.replace("'", "")

        if (isNullOrUndefined(caseId)) {
            return
        }

        if (isNullOrUndefined(metadataForFiles)) {
            return
        }


        if (!(finalCaseId in metadataForFiles)) {
            // Searches By name
            let par = Object.values(metadataForFiles).find(m => m[libCon.ATF_NAME].includes(finalCaseId))
            if (par !== undefined)
                finalCaseId = par[libCon.ATF_CASE_ID]
        }


        let metadata = metadataForFiles[finalCaseId]

        let sortedMatches = getMatches(metadata, participants)

        sortedMatches = sortedMatches.map(ob => { return ({ ...metadata, ...ob }) })

        setClosestMatches(sortedMatches)


    }

    return (
        <div className="mainContainer" >
            <Title level={3}>{`Match Checker`}</Title>

            <Space direction="horizontal" >
                <Input placeholder="Case ID or Name in File" value={caseId} onChange={(e) => setCaseID(e.target.value)} />
                <Button type="primary" onClick={() => showResults()}>Search</Button>
                <Button type="primary" onClick={() => setCaseID(null)} danger>Clear</Button>
            </Space>

            <div style={{ maxWidth: "98vw", marginTop: "20px" }}>
                {
                    closestMatches.length > 0
                        ? <Table dataSource={closestMatches} columns={GENERAL_COLUMNS} size="small" scroll={{ x: 'max-content', y: window.innerHeight * 0.65 }} style={{ fontSize: '12px' }} />
                        : <div></div>
                }
            </div>
        </div>
    )

}



function DashboardUploadLabResults() {


    const [participantStatus, participants] = RefParticipantsData()

    const [pdfFiles, setPdfFiles] = useState(null);
    const [metadataForFiles, setMetadataForFiles] = useState(null)

    const [resultsFile, setResultsFileFile] = useState(null);

    const [mappingFile, setMappingFile] = useState(null);

    const [isProcessing, setIsProcessing] = useState(false)
    const [processProgress, setProcessProgress] = useState(0)

    const [isUploading, setIsUploading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0)


    const [filesWithErrorArray, setFilesWithErrorArray] = useState([])
    const [duplicateCases, setDuplicateCases] = useState({})


    const [showSuccessfullRecords, setShowSuccessfullRecords] = useState(false)
    const [showDuplicateRecords, setShowDuplicateRecords] = useState(false)
    const [showErrorRecords, setShowErrorRecords] = useState(false)

    // For uplaoding the files
    const [uploadedIds, setUploadedIds] = useState(null) // Files that did upload
    const [uploadingErrorIds, setUploadingErrorIds] = useState(null) // Files that did upload
    const [missingInMappingFile, setMissingInMappingFile] = useState(null) // Files missing from the mapping file


    const [showUploadedRecords, setShowUploadedRecords] = useState(false)
    const [showUploadingErrorRecords, setShowUploadingErrorRecords] = useState(false)
    const [showMissingInMappingFileRecords, setShowMissingInMappingFileRecords] = useState(false)


    // Function to handle file processing
    const processResultsFile = async () => {

        setProcessProgress(0)

        // Resets Upload process
        setUploadProgress(0)
        setMappingFile(null)
        setUploadedIds([])
        setMissingInMappingFile([])
        setUploadingErrorIds([])

        await new Promise(resolve => setTimeout(resolve, 0)); // Let React update


        if (!resultsFile) {
            message.error("No file selected!");
            return;
        }

        if (participantStatus !== libCon.OK) {
            message.error("Participants not loaded");
            return;
        }

        setIsProcessing(true)


        const fileBytes = await resultsFile.arrayBuffer();

        // Split the PDF
        let [pdfArray, metadataArray, errorFiles] = await extractPDFsFromZip(fileBytes, (per) => setProcessProgress(per * 0.95));


        // iterates 
        metadataArray = metadataArray.map((metadata, i) => {
            setProcessProgress(95 + (5 * i / metadataArray.length));


            if (isNullOrUndefined(metadata[libCon.ATF_NAME]))
                return metadata

            let sortedMatches = getMatches(metadata, participants)

            return { ...metadata, ...sortedMatches[0] }

        })

        // converts to metadata dict
        let metadataDict = {}
        let pdfDicts = {}

        let duplicateFiles = {}

        metadataArray.forEach((metadata, i) => {

            const caseId = metadata[libCon.ATF_CASE_ID]
            const fileName = metadata[libCon.ATF_FILE_NAME]

            if (!(caseId in duplicateFiles))
                duplicateFiles[caseId] = []

            duplicateFiles[caseId].push(fileName)

            metadataDict[caseId] = metadata
            pdfDicts[caseId] = pdfArray[i]

        })

        // Creates the excel for download and manual process
        const now = new Date()
        exportToExcel(Object.values(metadataDict), `Lab Results ${format(now, "yyyy-MM-d")}.xlsx`, GENERAL_COLUMNS.map(ob => ob.dataIndex))

        setPdfFiles(pdfDicts);
        setMetadataForFiles(metadataDict)


        // Sets the records with error and duplicates
        setFilesWithErrorArray([...errorFiles])

        // Filters
        duplicateFiles = filterObjectByFunction(duplicateFiles, v => v.length > 1)

        setDuplicateCases({ ...duplicateFiles })


        setProcessProgress(100)
        setIsProcessing(false)
    };

    const processMappingFile = async () => {

        setUploadProgress(0)
        setUploadedIds([])
        setMissingInMappingFile([])
        setUploadingErrorIds([])

        if (!mappingFile) {
            message.error("No file selected!");
            return;
        }

        setIsUploading(true)



        let metadataArray = await parseExcelFile(mappingFile)
        const processedFiles = await Promise.all(metadataArray.map(async meta => await processPdf(pdfFiles[meta[libCon.ATF_CASE_ID]], meta[libCon.ATF_TARGET_SEWA_ID])))


        let newUploadedIds = []
        let newNotUploadedIds = Object.keys(pdfFiles)
        let newUploadingErrorIds = []


        for (let i = 0; i < metadataArray.length; i++) {

            // Sleeps for sustainability
            await sleep(3000)

            let metadata = metadataArray[i]
            let file = processedFiles[i]

            // Converts to String
            // Sewwa Id
            if (!isNullOrUndefined(metadata[libCon.ATF_TARGET_SEWA_ID]))
                metadata[libCon.ATF_TARGET_SEWA_ID] = `${metadata[libCon.ATF_TARGET_SEWA_ID]}`

            // Case Id
            if (!isNullOrUndefined(metadata[libCon.ATF_CASE_ID]))
                metadata[libCon.ATF_CASE_ID] = `${metadata[libCon.ATF_CASE_ID]}`

            // Removes from missing
            newNotUploadedIds = newNotUploadedIds.filter(id => id !== metadata[libCon.ATF_CASE_ID])

            let folder = `${libCon.LAB_RESULTS_FOLDER}/${metadata[libCon.ATF_TARGET_SEWA_ID]}`
            let filename = `${metadata[libCon.ATF_CASE_ID]}_${formatExport(metadata[libCon.ATF_SAMPLE_DATE])}.pdf`


            // Uploads to Airtable
            let participantId = null
            let participant = Object.values(participants).find(p => p[libCon.ATF_SEWA_ID] === metadata[libCon.ATF_TARGET_SEWA_ID])
            if (!isNullOrUndefined(participant))
                participantId = participant[libCon.ID]

            if (isNullOrUndefined(participantId)) {
                console.error(`Could not find participant with sewa id ${metadata[libCon.ATF_TARGET_SEWA_ID]}`)
                newUploadingErrorIds.push(metadata[libCon.ATF_CASE_ID])
                setUploadProgress(100 * i / metadataArray.length)
                continue
            }


            let [airtableResponse, responseRecord] = await uploadMapLabReportRecord(participantId, metadata)

            if (airtableResponse !== libCon.OK || isNullOrUndefined(responseRecord)) {
                console.error(`Could not add airtable mapping record for case: ${metadata[libCon.ATF_CASE_ID]}`)
                newUploadingErrorIds.push(metadata[libCon.ATF_CASE_ID])
                setUploadProgress(100 * i / metadataArray.length)
                continue
            }

            // Uploads to Bucket
            let bucketResponse = await uploadPDFToBucket(folder, filename, file)
            if (bucketResponse !== libCon.OK) {
                console.error(`Error: Failed to upload ${filename} to bucket`)
                newUploadingErrorIds.push(metadata[libCon.ATF_CASE_ID])
                setUploadProgress(100 * i / metadataArray.length)
                continue
            }


            // Adds to uploaded
            newUploadedIds.push(metadata[libCon.ATF_CASE_ID])
            setUploadProgress(100 * i / metadataArray.length)

        }

        setUploadedIds(newUploadedIds)
        setMissingInMappingFile(newNotUploadedIds)
        setUploadingErrorIds(newUploadingErrorIds)


        // Cleans Up
        setUploadProgress(100)
        setIsUploading(false)


        message.success(`Done`);
    }


    return (
        <div className="mainContainer" >
            <Title level={1}>{`Lab Uploads`}</Title>

            {/* Successfull records */}
            <Modal width={"70vw"}
                title="Successfull Records"
                open={showSuccessfullRecords}
                onCancel={() => setShowSuccessfullRecords(false)}
                footer={<DownloadFilesButton
                    pdfFiles={pdfFiles}
                    metadataForFiles={metadataForFiles}
                    keys={isNullOrUndefined(metadataForFiles) ? [] : Object.keys(metadataForFiles)}
                    fileName={"imported_successfully.zip"} />}>
                {
                    !isNullOrUndefined(metadataForFiles)
                        ? Object.keys(metadataForFiles).map((key, i) => {
                            return (
                                <div key={i}>
                                    <p>{key}: {metadataForFiles[key][libCon.ATF_FILE_NAME]}</p>
                                </div>
                            )
                        }

                        )
                        : <div></div>
                }
            </Modal>

            {/* Duplicate records */}
            <Modal width={"70vw"} title="Duplicate Records" open={showDuplicateRecords} onCancel={() => setShowDuplicateRecords(false)} footer={null}>
                {
                    !isNullOrUndefined(duplicateCases)
                        ? Object.keys(duplicateCases).map((key, i) => {
                            return (
                                <div style={{ marginBottom: "20px" }} key={i}>
                                    <p style={{ marginTop: "1px", marginBottom: "1px" }}>{key}: {duplicateCases[key].length} times</p>
                                    {
                                        duplicateCases[key].map((file, j) => { return (<p style={{ marginLeft: "15px", marginTop: "1px", marginBottom: "1px" }} key={j}>{`   ${file}`}</p>) })
                                    }
                                </div>
                            )
                        }

                        )
                        : <div></div>
                }
            </Modal>


            {/* Error records */}
            <Modal title="Error Records" open={showErrorRecords} onCancel={() => setShowErrorRecords(false)} footer={null}>
                {
                    !isNullOrUndefined(filesWithErrorArray)
                        ? filesWithErrorArray.map((key, i) => {
                            return (
                                <div key={i}>
                                    <p>{key}</p>
                                </div>
                            )
                        }

                        )
                        : <div></div>
                }
            </Modal>


            {/* Uploaded Records */}
            <Modal width={"70vw"}
                title="Uploaded Records"
                open={showUploadedRecords}
                onCancel={() => setShowUploadedRecords(false)}
                footer={<DownloadFilesButton
                    pdfFiles={pdfFiles}
                    metadataForFiles={metadataForFiles}
                    keys={uploadedIds}
                    fileName={"uploaded_successfull.zip"} />}>
                {
                    !isNullOrUndefined(uploadedIds) && !isNullOrUndefined(metadataForFiles)
                        ? uploadedIds.map((key, i) => {
                            return (
                                <div key={i}>
                                    <p>{key}: {metadataForFiles[key][libCon.ATF_FILE_NAME]}</p>
                                </div>
                            )
                        }

                        )
                        : <div></div>
                }
            </Modal>


            {/* Missing Records */}
            <Modal width={"70vw"}
                title="Missing in File"
                open={showMissingInMappingFileRecords}
                onCancel={() => setShowMissingInMappingFileRecords(false)}
                footer={<DownloadFilesButton
                    pdfFiles={pdfFiles}
                    metadataForFiles={metadataForFiles}
                    keys={missingInMappingFile}
                    fileName={"missing_from_upload.zip"} />}>
                {
                    !isNullOrUndefined(missingInMappingFile) && !isNullOrUndefined(metadataForFiles)
                        ? missingInMappingFile.map((key, i) => {
                            return (
                                <div key={i}>
                                    <p>{key}: {metadataForFiles[key][libCon.ATF_FILE_NAME]}</p>
                                </div>
                            )
                        }

                        )
                        : <div></div>
                }
            </Modal>


            {/* uploading errors */}
            <Modal width={"70vw"} title="Uploading Errors" open={showUploadingErrorRecords} onCancel={() => setShowUploadingErrorRecords(false)} footer={null}>
                {
                    !isNullOrUndefined(uploadingErrorIds) && !isNullOrUndefined(metadataForFiles)
                        ? uploadingErrorIds.map((key, i) => {
                            return (
                                <div key={i}>
                                    <p>{key}: {metadataForFiles[key][libCon.ATF_FILE_NAME]}</p>
                                </div>
                            )
                        }

                        )
                        : <div></div>
                }
            </Modal>


            {/* Results File */}

            {
                participantStatus !== libCon.OK
                    ? <LoadingParticipants />
                    : <div className="mainContainer">
                        <Card style={{ width: "40vw" }}
                            styles={{ header: { backgroundColor: "var(--primary-color-3)" } }}
                            type="inner" size="medium" title="Upload Lab Results">


                            <Upload
                                beforeUpload={(uploadedFile) => {
                                    setResultsFileFile(uploadedFile); // Store the uploaded file
                                    message.success(`Lab results uploaded: ${uploadedFile.name}`);
                                    setProcessProgress(0)
                                    setUploadProgress(0)
                                    setMappingFile(null)

                                    return false; // Prevent automatic upload
                                }}
                                showUploadList={false} // Hide default file list
                            >
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>

                            {!isNullOrUndefined(resultsFile)
                                ? <p style={{ marginTop: "10px" }}>Selected File: {resultsFile.name}</p>
                                : <div style={{ marginTop: "10px" }}></div>
                            }

                            {/* Process File Button */}
                            {
                                isProcessing
                                    ? <Spin />
                                    : <Button
                                        type="primary"
                                        style={{ marginTop: "10px" }}
                                        onClick={processResultsFile}
                                    >
                                        Process File
                                    </Button>
                            }
                            {
                                processProgress === 100
                                    ? <div>
                                        <p style={{ marginTop: "10px" }}>Total Files: {Object.keys(pdfFiles).length + filesWithErrorArray.length + sumArray(Object.values(duplicateCases).map(l => l.length - 1))} </p>
                                        <Space direction="horizontal">
                                            <Tag onClick={() => setShowSuccessfullRecords(true)} color="green" style={{ marginTop: "10px", fontSize: '16px', padding: '6px 12px' }}>Succesfu: {Object.keys(pdfFiles).length} </Tag>
                                            <Tag onClick={() => setShowDuplicateRecords(true)} color="gold" style={{ marginTop: "10px", fontSize: '16px', padding: '6px 12px' }}>Duplicate cases: {sumArray(Object.values(duplicateCases).map(l => l.length - 1))}</Tag>
                                            <Tag onClick={() => setShowErrorRecords(true)} color="red" style={{ marginTop: "10px", fontSize: '16px', padding: '6px 12px' }}>Files with errors: {filesWithErrorArray.length}</Tag>
                                        </Space>
                                    </div>
                                    : <div style={{ height: "30px" }}></div>
                            }

                            <Progress style={{ width: "100%" }} percent={processProgress} status='success' />


                        </Card>


                        <Card style={{ marginTop: "10px", width: "40vw" }}
                            styles={{ header: { backgroundColor: "var(--primary-color-3)" } }}
                            type="inner" size="medium" title="Upload Mapping File">



                            {/* Mapping File */}
                            <Upload
                                beforeUpload={(uploadedFile) => {
                                    setMappingFile(uploadedFile); // Store the uploaded file
                                    message.success(`Mapping File ${uploadedFile.name}`);
                                    setUploadProgress(0)

                                    return false; // Prevent automatic upload
                                }}
                                showUploadList={false} // Hide default file list
                            >
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>

                            {!isNullOrUndefined(mappingFile)
                                ? <p style={{ marginTop: "10px" }}>Selected File: {mappingFile.name}</p>
                                : <div style={{ marginTop: "10px" }}></div>
                            }

                            {/* Process File Button */}

                            {
                                isUploading === true
                                    ? <Spin />
                                    : <Button
                                        type="primary"
                                        style={{ marginTop: "10px" }}
                                        onClick={processMappingFile}


                                    >
                                        Process File
                                    </Button>
                            }

                            {
                                uploadProgress === 100
                                    ? <div>
                                        <Space direction="horizontal">
                                            <Tag onClick={() => setShowUploadedRecords(true)} color="green" style={{ marginTop: "10px", fontSize: '16px', padding: '6px 12px' }}>Uploaded: {uploadedIds.length} </Tag>
                                            <Tag onClick={() => setShowMissingInMappingFileRecords(true)} color="gold" style={{ marginTop: "10px", fontSize: '16px', padding: '6px 12px' }}>Missing in File: {missingInMappingFile.length}</Tag>
                                            <Tag onClick={() => setShowUploadingErrorRecords(true)} color="red" style={{ marginTop: "10px", fontSize: '16px', padding: '6px 12px' }}>Not Uploaded: {uploadingErrorIds.length}</Tag>

                                        </Space>
                                    </div>
                                    : <div style={{ height: "30px" }}></div>
                            }


                            <Progress style={{ width: "100%" }} percent={uploadProgress} status='success' />

                        </Card>

                        {
                            isNullOrUndefinedOrEmpty(pdfFiles)
                                ? <div></div>
                                : <MatchChecker metadataForFiles={metadataForFiles} participants={participants} />

                        }

                    </div>
            }


        </div>
    )
}

export default DashboardUploadLabResults