// Style funcitons
import * as libCon from "../Constants.js"
import { parseISO } from "date-fns"
import { getDaysBetweenDates } from "./dateFunctions.js"

export const getEndDateStyle = (val, record) => {
    let response = {
        color: "black",
        fontWeight: "normal"
    }

    if (getDaysBetweenDates(parseISO(record[libCon.ATF_END_DATE]), parseISO(record[libCon.ATF_DATE_UPLOADED])) >= 2) {
        response = {
            ...response,
            color: "var(--warning-color-1)",
            fontWeight: "bold"

        }
    }


    return response
}

export const getDaysStyle = (val, record) => {
    let response = {
        color: "black",
        fontWeight: "normal"
    }
    if (val <= 10) {
        response = {
            ...response,
            color: "var(--warning-color-1)",
            fontWeight: "bold"

        }
    }


    return response
}