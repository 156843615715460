// Functions to determine a device status
import * as dt_fun from './dateFunctions.js';
import * as libCon from '../Constants.js';
import { BugOutlined, CheckCircleTwoTone, ClockCircleTwoTone, ClockCircleOutlined, QuestionCircleOutlined, WarningTwoTone, ToolOutlined, RetweetOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import { filterObjectByFunction, isNullOrUndefined, isNullOrUndefinedOrEmpty } from './generalFunctions.js';


const { Text } = Typography;


const VISIT_COLLECTION_WINDOW_HOURS = 12
const WAITING_FOR_COLLECTION_WINDOW_DAYS = 10
const MAX_GAP_BETWEEN_FILES_DAYS = 1
const MAX_GAP_SINCE_COLLECTION_AND_LATEST_FILE_DAYS = 2
const MIN_ACCEPTABLE_EXPORT_WINDOW_DAYS = 17
const MAX_GAP_FORM_PLACEMENT_START_TO_EXPORT_DAYS = 1
const MAX_ENVIRONMENTAL_SENSOR_CAPACITY_DAYS = 20
const MAX_WEARABLE_CAPACITY_DAYS = 30
const MAX_MINUTES_SINCE_HEART_RATE = 17
const HEART_RATE_WINDOW_AROUND_LAST_COLLECTION = 25



export const SymbolForStatus = ({ status, message, size = 16, translation = libCon.dashboardTranslation }) => {

    const translatedMessage = translation(message)

    switch (status) {
        case libCon.DEVICE_STATUS_OK:
        case libCon.OK:
            return <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} title={translatedMessage}><CheckCircleTwoTone style={{ fontSize: size }} twoToneColor="#52c41a" /></Tooltip>
        case libCon.DEVICE_STATUS_WAITING_COLLECTION:
            return <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} title={translatedMessage}><ClockCircleOutlined style={{ fontSize: size }} /></Tooltip>
        case libCon.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA:
            return <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} title={translatedMessage}><ClockCircleTwoTone style={{ fontSize: size }} twoToneColor="#e91822" /></Tooltip>
        case libCon.DEVICE_STATUS_ERROR_IN_COLLECTION:
            return <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} title={translatedMessage}><WarningTwoTone style={{ fontSize: size }} twoToneColor="#e91822" /></Tooltip>
        case libCon.DEVICE_STATUS_INCOMPLETE_DEPLOYMENT:
            return <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} title={translatedMessage}><ToolOutlined style={{ fontSize: size }} twoToneColor="#e91822" /></Tooltip>
        case libCon.DEVICE_STATUS_HARDWARE_MALFUNCTION:
            return <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} title={translatedMessage}><BugOutlined style={{ fontSize: size }} /></Tooltip>
        case libCon.DEVICE_STATUS_NEEDS_REFRESH:
            return <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} title={translatedMessage}><RetweetOutlined style={{ fontSize: size }} /></Tooltip>
        default:
            return <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} title={translatedMessage}><QuestionCircleOutlined style={{ fontSize: size }} /></Tooltip>
    }

}

export const DeviceStatus = ({ text, status, message }) => {

    return (<div style={{ width: "100%", display: "flex" }}>
        <div style={{ marginLeft: "0.5vh", marginRight: "0.5vh" }}>
            <SymbolForStatus status={status} message={message} size={30} />
        </div>
        <Text style={{ fontSize: "1.8vh", textAlign: "center", marginTop: "0.8vh" }}>{text}</Text>

    </div>)

}



export const getIntakeFormStatus = (intakeStatus, lastChecked, extraQuestions) => {


    if (isNullOrUndefined(extraQuestions))
        extraQuestions = {}

    // Unloaded values
    if (isNullOrUndefined(intakeStatus)) {
        return [libCon.DEVICE_STATUS_INCOMPLETE_DEPLOYMENT, libCon.MESSAGE_INCOMPLETE_DEPLOYMENT]
    }

    if (isNullOrUndefined(lastChecked)) {
        return [libCon.DEVICE_STATUS_NEEDS_REFRESH, libCon.MESSAGE_PLEASE_REFRESH]
    }



    let hoursAgoChecked = dt_fun.getDaysBetweenDates(lastChecked, new Date()) * 24

    // To much time has passed
    if (hoursAgoChecked > VISIT_COLLECTION_WINDOW_HOURS)
        return [libCon.DEVICE_STATUS_NEEDS_REFRESH, libCon.MESSAGE_PLEASE_REFRESH]

    // Filters for Pending 
    extraQuestions = filterObjectByFunction(extraQuestions, i => i[libCon.ATF_STATUS] === libCon.ATF_PENDING)

    if (Object.keys(extraQuestions).length > 0)
        return [libCon.DEVICE_STATUS_WAITING_COLLECTION, libCon.MESSAGE_HAS_PENDING_QUESTIONS]


    switch (intakeStatus) {
        case libCon.ATF_COMPLETE:
            return [libCon.DEVICE_STATUS_OK, libCon.MESSAGE_INTAKE_COMPLETE]
        case libCon.ATF_PENDING:
            return [libCon.DEVICE_STATUS_WAITING_COLLECTION, libCon.MESSAGE_INTAKE_WAITING]
        case libCon.ATF_PENDING_QUESTIONS:
            return [libCon.DEVICE_STATUS_WAITING_COLLECTION, libCon.MESSAGE_HAS_PENDING_QUESTIONS]
        default:
            return [libCon.DEVICE_STATUS_ERROR_IN_COLLECTION, "Status not recognized"];
    }
}

export const getPhonePlacementStatus = (latestUpload, latestHouseVisit) => {

    if (latestUpload === null || latestUpload === undefined)
        return [libCon.DEVICE_STATUS_ERROR_IN_COLLECTION, libCon.MESSAGE_DATA_WAS_NOT_COLLECTED]

    let daysBetweenVisit = dt_fun.getDaysBetweenDates(latestHouseVisit, latestUpload)
    let hoursBetweenVisit = daysBetweenVisit * 24

    let daysSinceLastUpload = dt_fun.getDaysBetweenDates(latestUpload, new Date())

    // Data was not collected
    if (hoursBetweenVisit < -1 * VISIT_COLLECTION_WINDOW_HOURS)
        return [libCon.DEVICE_STATUS_ERROR_IN_COLLECTION, libCon.MESSAGE_DATA_WAS_NOT_COLLECTED]


    // Waiting for Data collection
    if (daysSinceLastUpload >= WAITING_FOR_COLLECTION_WINDOW_DAYS)
        return [libCon.DEVICE_STATUS_WAITING_COLLECTION, libCon.MESSAGE_WAITING_FOR_COLLECTION]

    // Last time stamp was inside VISIT COLLECTION WINDOW or after the latest house visit
    return [libCon.DEVICE_STATUS_OK, libCon.MESSAGE_DATA_UP_TO_DATE]


}


export const getIssuesStatus = (issues, lastIssueCheck) => {

    if (isNullOrUndefined(issues))
        issues = {}


    if (isNullOrUndefined(lastIssueCheck))
        return [libCon.DEVICE_STATUS_NEEDS_REFRESH, libCon.MESSAGE_PLEASE_REFRESH]

    let hoursAgoChecked = dt_fun.getDaysBetweenDates(lastIssueCheck, new Date()) * 24

    // To much time has passed
    if (hoursAgoChecked > VISIT_COLLECTION_WINDOW_HOURS)
        return [libCon.DEVICE_STATUS_NEEDS_REFRESH, libCon.MESSAGE_PLEASE_REFRESH]

    // Filters for Pending and Ground Team
    issues = filterObjectByFunction(issues, i => i[libCon.ATF_RESPONSIBLE] === libCon.ATF_GROUND_TEAM && i[libCon.ATF_STATUS] === libCon.ATF_PENDING)

    if (Object.keys(issues).length > 0)
        return [libCon.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA, libCon.MESSAGE_STILL_HAS_PENDING_ISSUES]


    // No pending Issues
    return [libCon.DEVICE_STATUS_OK, libCon.MESSAGE_NO_ISSUES]


}



export const getExtraRequestStatus = (pendingItems, lastExtraRequestCheck) => {

    if (isNullOrUndefined(pendingItems))
        pendingItems = []


    if (isNullOrUndefined(lastExtraRequestCheck))
        return [libCon.DEVICE_STATUS_NEEDS_REFRESH, libCon.MESSAGE_PLEASE_REFRESH]

    let hoursAgoChecked = dt_fun.getDaysBetweenDates(lastExtraRequestCheck, new Date()) * 24

    // To much time has passed
    if (hoursAgoChecked > VISIT_COLLECTION_WINDOW_HOURS)
        return [libCon.DEVICE_STATUS_NEEDS_REFRESH, libCon.MESSAGE_PLEASE_REFRESH]

    // Checks if any are pendng
    if (pendingItems.length > 0)
        return [libCon.DEVICE_STATUS_WAITING_COLLECTION, libCon.MESSAGE_HAS_PENDING_EXTRA_REQUESTS]

    // No pending Extra requests
    return [libCon.DEVICE_STATUS_OK, libCon.MESSAGE_NO_EXTRA_REQUESTS]


}


export const getWearablePlacementStatus = (latestUpload, latestHeartRateTimestamp, latestHouseVisit) => {
    let daysBetweenVisit = dt_fun.getDaysBetweenDates(latestHouseVisit, latestUpload)
    let hoursBetweenVisit = daysBetweenVisit * 24

    let daysSinceLastUpload = dt_fun.getDaysBetweenDates(latestUpload, new Date())

    // Data was not collected
    if (hoursBetweenVisit < -1 * VISIT_COLLECTION_WINDOW_HOURS)
        return [libCon.DEVICE_STATUS_ERROR_IN_COLLECTION, libCon.MESSAGE_DATA_WAS_NOT_COLLECTED]

    // Waiting for Data collection
    if (daysSinceLastUpload >= WAITING_FOR_COLLECTION_WINDOW_DAYS) {
        if (daysSinceLastUpload > MAX_WEARABLE_CAPACITY_DAYS)
            return [libCon.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA, libCon.MESSAGE_WAITING_FOR_COLLECTION_LOOSING_DATA]

        return [libCon.DEVICE_STATUS_WAITING_COLLECTION, libCon.MESSAGE_WAITING_FOR_COLLECTION]
    }

    // Heartrate is okay if.
    // * It's x minutes behind last synch
    // * It's in the window of latest House visit

    // Now checks Heart Rate
    if (!isNullOrUndefinedOrEmpty(latestHeartRateTimestamp) &&
        (dt_fun.getMinutesBetweenDates(latestHeartRateTimestamp, latestUpload) <= MAX_MINUTES_SINCE_HEART_RATE
            || dt_fun.getMinutesBetweenDates(latestHouseVisit, latestHeartRateTimestamp) >= -1 * HEART_RATE_WINDOW_AROUND_LAST_COLLECTION))
        return [libCon.DEVICE_STATUS_OK, libCon.MESSAGE_DATA_UP_TO_DATE]

    // Heart rate not up to date
    return [libCon.DEVICE_STATUS_ERROR_IN_COLLECTION, libCon.MESSAGE_HEART_RATE_NOT_RECENT]




}

export const getPerceptualSurveyStatus = (latestUpload, latestHouseVisit) => {


    if (isNullOrUndefined(latestUpload))
        return [libCon.DEVICE_STATUS_WAITING_COLLECTION, libCon.MESSAGE_WAITING_FOR_COLLECTION]

    let daysBetweenVisit = dt_fun.getDaysBetweenDates(latestHouseVisit, latestUpload)
    let hoursBetweenVisit = daysBetweenVisit * 24

    let daysSinceLastUpload = dt_fun.getDaysBetweenDates(latestUpload, new Date())

    // Data was not collected
    if (hoursBetweenVisit < -1 * VISIT_COLLECTION_WINDOW_HOURS)
        return [libCon.DEVICE_STATUS_ERROR_IN_COLLECTION, libCon.MESSAGE_DATA_WAS_NOT_COLLECTED]


    // Waiting for Data collection
    if (daysSinceLastUpload >= WAITING_FOR_COLLECTION_WINDOW_DAYS)
        return [libCon.DEVICE_STATUS_WAITING_COLLECTION, libCon.MESSAGE_WAITING_FOR_COLLECTION]


    // Last time stamp was inside VISIT COLLECTION WINDOW or after the latest house visit
    return [libCon.DEVICE_STATUS_OK, libCon.MESSAGE_DATA_UP_TO_DATE]


}


export const getSensorPlacementStatus = (brand, model, latestFiles, latestHouseVisit, placementStart, lastBatteryChange) => {

    if (brand === libCon.HOBO && model === libCon.U12_HOBO_MODEL) // Collection is handled by CEPT team. 
        return [libCon.DEVICE_STATUS_OK, libCon.MESSAGE_HANDLED_BY_CEPT]


    if (latestFiles.length === 0)
        return [libCon.DEVICE_STATUS_ERROR_IN_COLLECTION, libCon.MESSAGE_NO_FILES_HAVE_BEEN_RECEIVED]

    // Computes latest export window
    // First sorts
    latestFiles = latestFiles.map(f => f)
    latestFiles.sort((f1, f2) => new Date(f2[libCon.ATF_END_DATE]) - new Date(f1[libCon.ATF_END_DATE]))

    //Extracts lastUpload
    let latestUpload = Math.max(...latestFiles.map(f => new Date(f[libCon.ATF_DATE_UPLOADED])))

    // Extracts start date and end date
    let endDate = new Date(latestFiles[0][libCon.ATF_END_DATE])
    let startDate = new Date(latestFiles[0][libCon.ATF_START_DATE])

    // Loop through the array
    for (let i = 1; i < latestFiles.length; i++) {

        // Checks for intersection
        let tempEndDate = new Date(latestFiles[i][libCon.ATF_END_DATE])
        if (((tempEndDate - startDate) / (1000 * 60 * 60 * 24)) >= -1 * MAX_GAP_BETWEEN_FILES_DAYS)
            startDate = Math.min(startDate, new Date(latestFiles[i][libCon.ATF_START_DATE]))
        else
            break
    }


    let daysBetweenVisit = dt_fun.getDaysBetweenDates(latestHouseVisit, latestUpload)
    let hoursBetweenVisit = daysBetweenVisit * 24

    let daysSinceLastUpload = dt_fun.getDaysBetweenDates(latestUpload, new Date())


    let exportWindow = dt_fun.getDaysBetweenDates(startDate, endDate)


    // Data was not collected
    if (hoursBetweenVisit < -1 * VISIT_COLLECTION_WINDOW_HOURS)
        return [libCon.DEVICE_STATUS_ERROR_IN_COLLECTION, libCon.MESSAGE_DATA_WAS_NOT_COLLECTED]


    // Waiting for Data collection
    if (daysSinceLastUpload >= WAITING_FOR_COLLECTION_WINDOW_DAYS) {
        if (daysSinceLastUpload > MAX_ENVIRONMENTAL_SENSOR_CAPACITY_DAYS)
            return [libCon.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA, libCon.MESSAGE_WAITING_FOR_COLLECTION_LOOSING_DATA]

        return [libCon.DEVICE_STATUS_WAITING_COLLECTION, libCon.MESSAGE_WAITING_FOR_COLLECTION]
    }



    // Assumes data was collected and will check for quality
    let daysBetweenEndDateAndVisit = dt_fun.getDaysBetweenDates(endDate, latestHouseVisit)
    let daysSinceDeployment = dt_fun.getDaysBetweenDates(placementStart, latestHouseVisit)
    let daysSinceLastBatteryChange = dt_fun.getDaysBetweenDates(lastBatteryChange, latestHouseVisit)

    // File not recent enough
    if (daysBetweenEndDateAndVisit > MAX_GAP_SINCE_COLLECTION_AND_LATEST_FILE_DAYS) {
        if (brand === libCon.HOBO && (daysSinceLastBatteryChange - exportWindow) <= MAX_GAP_FORM_PLACEMENT_START_TO_EXPORT_DAYS)
            return [libCon.DEVICE_STATUS_OK, libCon.MESSAGE_BATTERY_CHANGE_JUST_HAPPENED]


        return [libCon.DEVICE_STATUS_ERROR_IN_COLLECTION, libCon.MESSAGE_SENSOR_FILE_NOT_RECENT_ENOUGH]

    }


    // File not long enough
    if (exportWindow < MIN_ACCEPTABLE_EXPORT_WINDOW_DAYS) {
        if ((daysSinceDeployment - exportWindow) <= MAX_GAP_FORM_PLACEMENT_START_TO_EXPORT_DAYS)
            return [libCon.DEVICE_STATUS_OK, libCon.MESSAGE_PLACEMENT_JUST_STARTED]

        if ((daysSinceLastBatteryChange - exportWindow) <= MAX_GAP_FORM_PLACEMENT_START_TO_EXPORT_DAYS)
            return [libCon.DEVICE_STATUS_OK, libCon.MESSAGE_BATTERY_CHANGE_JUST_HAPPENED]


        return [libCon.DEVICE_STATUS_ERROR_IN_COLLECTION, libCon.MESSAGE_SENSOR_FILE_NOT_LONG_ENOUGH]

    }



    // Last time stamp was inside VISIT COLLECTION WINDOW or after the latest house visit
    return [libCon.DEVICE_STATUS_OK, libCon.MESSAGE_DATA_UP_TO_DATE]


}

export const getUnifyingStatusAndMessageForSensors = (sensors, translation = libCon.dashboardTranslation) => {


    if (isNullOrUndefinedOrEmpty(sensors))
        return [libCon.DEVICE_STATUS_INCOMPLETE_DEPLOYMENT, libCon.MESSAGE_NO_SENSORS_IN_BUNDLE]

    // All of the sensors are up to date
    if (sensors.every(s => s[libCon.ATF_DEVICE_COLLECTION_STATUS] === libCon.DEVICE_STATUS_OK))
        return [libCon.DEVICE_STATUS_OK, sensors[0][libCon.ATF_DEVICE_COLLECTION_MESSAGE]]

    // All of the sensors waiting for collection
    if (sensors.every(s => s[libCon.ATF_DEVICE_COLLECTION_STATUS] === libCon.DEVICE_STATUS_WAITING_COLLECTION))
        return [libCon.DEVICE_STATUS_WAITING_COLLECTION, sensors[0][libCon.ATF_DEVICE_COLLECTION_MESSAGE]]

    // All of the sensors waiting for collection or up to date
    if (sensors.every(s => s[libCon.ATF_DEVICE_COLLECTION_STATUS] === libCon.DEVICE_STATUS_WAITING_COLLECTION
        || s[libCon.ATF_DEVICE_COLLECTION_STATUS] === libCon.DEVICE_STATUS_OK)) {
        let arrStrings = sensors.filter(s => s[libCon.ATF_DEVICE_COLLECTION_STATUS] === libCon.DEVICE_STATUS_WAITING_COLLECTION).map(s => `${s[libCon.ATF_BRAND]} ${s[libCon.ATF_SERIAL]}: ${translation(s[[libCon.ATF_DEVICE_COLLECTION_MESSAGE]])}`)

        return [libCon.DEVICE_STATUS_WAITING_COLLECTION, arrStrings.join('\n')]

    }

    // At least one has an error
    if (sensors.some(s => s[libCon.ATF_DEVICE_COLLECTION_STATUS] === libCon.DEVICE_STATUS_ERROR_IN_COLLECTION)) {
        let arrStrings = sensors.filter(s => s[libCon.ATF_DEVICE_COLLECTION_STATUS] === libCon.DEVICE_STATUS_ERROR_IN_COLLECTION).map(s => `${s[libCon.ATF_BRAND]} ${s[libCon.ATF_SERIAL]}: ${translation(s[[libCon.ATF_DEVICE_COLLECTION_MESSAGE]])}`)
        return [libCon.DEVICE_STATUS_ERROR_IN_COLLECTION, arrStrings.join('\n')]
    }

    // At least one is loosing days
    if (sensors.some(s => s[libCon.ATF_DEVICE_COLLECTION_STATUS] === libCon.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA)) {
        let arrStrings = sensors.filter(s => s[libCon.ATF_DEVICE_COLLECTION_STATUS] === libCon.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA).map(s => `${s[libCon.ATF_BRAND]} ${s[libCon.ATF_SERIAL]}: ${translation(s[[libCon.ATF_DEVICE_COLLECTION_MESSAGE]])}`)
        return [libCon.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA, arrStrings.join('\n')]
    }


    // There are no sensors with error and at least no not up to date and not waiting for collection
    let arrStrings = sensors.filter(s => s[libCon.ATF_DEVICE_COLLECTION_STATUS] !== libCon.DEVICE_STATUS_OK && s[libCon.ATF_DEVICE_COLLECTION_STATUS] !== libCon.DEVICE_STATUS_WAITING_COLLECTION && s[libCon.ATF_DEVICE_COLLECTION_STATUS] !== libCon.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA).map(s => `${s[libCon.ATF_BRAND]} ${s[libCon.ATF_SERIAL]}: ${translation(s[[libCon.ATF_DEVICE_COLLECTION_MESSAGE]])}`)
    return [libCon.DEVICE_STATUS_ERROR_IN_COLLECTION, arrStrings.join('\n')]


}


export const getAllIntakeFormsStatus = (pifStatus, pifMessage, hifStatus, hifMessage, translation = libCon.dashboardTranslation) => {

    let status = [pifStatus, hifStatus]


    // At least one has incomplete deployment
    if (status.some(s => s === libCon.DEVICE_STATUS_INCOMPLETE_DEPLOYMENT))
        return [libCon.DEVICE_STATUS_INCOMPLETE_DEPLOYMENT, libCon.MESSAGE_INCOMPLETE_DEPLOYMENT]


    // All of the status are up to date
    if (status.every(s => s === libCon.DEVICE_STATUS_OK))
        return [libCon.DEVICE_STATUS_OK, libCon.MESSAGE_DATA_UP_TO_DATE]


    // All of the status are pendng collection
    if (status.every(s => s === libCon.DEVICE_STATUS_WAITING_COLLECTION))
        return [libCon.DEVICE_STATUS_WAITING_COLLECTION, libCon.MESSAGE_WAITING_FOR_COLLECTION]


    let arrStrings = [
        `Participant Intake Form: ${translation(pifMessage)}`,
        `House Intake Form: ${translation(hifMessage)}`

    ]


    return [libCon.DEVICE_STATUS_WAITING_COLLECTION, arrStrings.join('\n')]



}


export const getUnifyingStatusAndMessageForParticipant = (participant, translation = libCon.dashboardTranslation) => {
    if (!participant[libCon.ATF_HAS_ACTIVE_SENSORS] || !participant[libCon.ATF_HAS_ACTIVE_PHONES] || !participant[libCon.ATF_HAS_ACTIVE_WEARABLES])
        return [libCon.DEVICE_STATUS_INCOMPLETE_DEPLOYMENT, libCon.MESSAGE_INCOMPLETE_DEPLOYMENT]

    let sensorStatus = participant[libCon.AT_SENSORS_COLLECTION_STATUS]
    let sensorMessage = participant[libCon.AT_SENSORS_COLLECTION_MESSAGE]
    let phoneStatus = participant[libCon.ATF_ACTIVE_PHONE][libCon.ATF_DEVICE_COLLECTION_STATUS]
    let phoneMessage = participant[libCon.ATF_ACTIVE_PHONE][libCon.ATF_DEVICE_COLLECTION_MESSAGE]
    let wearableStatus = participant[libCon.ATF_ACTIVE_EMAIL][libCon.ATF_DEVICE_COLLECTION_STATUS]
    let wearableMessage = participant[libCon.ATF_ACTIVE_EMAIL][libCon.ATF_DEVICE_COLLECTION_MESSAGE]
    //let intakeFormStatus = participant[libCon.ATF_INTAKE_SURVEY][libCon.ATF_DEVICE_COLLECTION_STATUS]
    //let intakeFormMessage = participant[libCon.ATF_INTAKE_SURVEY][libCon.ATF_DEVICE_COLLECTION_MESSAGE]

    let status = [sensorStatus, phoneStatus, wearableStatus]


    // All of the status are up to date
    if (status.every(s => s === libCon.DEVICE_STATUS_OK))
        return [libCon.DEVICE_STATUS_OK, libCon.MESSAGE_DATA_UP_TO_DATE]

    // All of the status are up to date or waiting waiting for collection.
    if (status.filter(s => s !== libCon.DEVICE_STATUS_OK).every(s => s === libCon.DEVICE_STATUS_WAITING_COLLECTION))
        return [libCon.DEVICE_STATUS_WAITING_COLLECTION, libCon.MESSAGE_WAITING_FOR_COLLECTION]

    // At least one has an error
    if (status.some(s => s === libCon.DEVICE_STATUS_ERROR_IN_COLLECTION)) {
        let arrStrings = []
        if (sensorStatus === libCon.DEVICE_STATUS_ERROR_IN_COLLECTION)
            arrStrings.push(`${libCon.DEVICE_SENSORS}: ${translation(sensorMessage)}`)
        if (phoneStatus === libCon.DEVICE_STATUS_ERROR_IN_COLLECTION)
            arrStrings.push(`${libCon.DEVICE_PHONE}: ${translation(phoneMessage)}`)
        if (wearableStatus === libCon.DEVICE_STATUS_ERROR_IN_COLLECTION)
            arrStrings.push(`${libCon.DEVICE_WEARABLE}: ${translation(wearableMessage)}`)
        // if (intakeFormStatus === libCon.DEVICE_STATUS_ERROR_IN_COLLECTION)
        //     arrStrings.push(`${libCon.ATF_INTAKE_SURVEY}: ${intakeFormMessage}`)


        return [libCon.DEVICE_STATUS_ERROR_IN_COLLECTION, arrStrings.join('\n')]
    }

    // At least one is loosing days
    if (status.some(s => s === libCon.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA)) {
        let arrStrings = []
        if (sensorStatus === libCon.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA)
            arrStrings.push(`${libCon.DEVICE_SENSORS}: ${translation(sensorMessage)}`)
        if (phoneStatus === libCon.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA)
            arrStrings.push(`${libCon.DEVICE_PHONE}: ${translation(phoneMessage)}`)
        if (wearableStatus === libCon.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA)
            arrStrings.push(`${libCon.DEVICE_WEARABLE}: ${translation(wearableMessage)}`)

        return [libCon.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA, arrStrings.join('\n')]
    }


    return [libCon.DEVICE_STATUS_OTHER, ""]

}

export const getFinalPercentageFromWearableCoverage = (percentage) => {
    if (!isNullOrUndefined(percentage)) {
        let roundNumber = 10
        let response = Math.round(100 * percentage / roundNumber) * roundNumber
        return Math.round(response)
    }


    return 0
}

export const getFinalColorFromWearableCoverage = (percentage) => {


    const colors = [
        "green", // green
        "#0F6F32", // Natural Forest Green
        "#157F3B", // Lush Woodland Green
        "#3AAB72", // Vibrant Leafy Green
        "#94D2BD", // Soft Mint
        "#E9D8A6", // Warm Sand
        "#EE9B00", // Golden Yellow
        "#CA6702", // Deep Orange
        "#BB3E03", // Rust Red
        "#9B2226"  // Deep Brick Red
    ];

    if (!isNullOrUndefined(percentage)) {

        if (percentage > 1)
            percentage /= 100

        let i = Math.floor((1 - percentage) * colors.length)

        if (i >= colors.length)
            i = colors.length - 1

        return colors[i]
    }

    return "black"
}


export const getFinalTextFromWearableCoverage = (percentage) => {


    const texts = [
        "Perfect", // 100
        "Excellent", // 90
        "Very Good", // 80
        "Good", // 70
        "Good", // 60
        "Fair", // 50
        "Fair", // 40
        "Poor", // 30
        "Poor", // 20
        "Very Poor"  // 10
    ];

    if (!isNullOrUndefined(percentage)) {

        if (percentage > 1)
            percentage /= 100

        let i = Math.round((1 - percentage) * texts.length)

        if (i >= texts.length)
            i = texts.length - 1

        return texts[i]
    }

    return "No info"
}
