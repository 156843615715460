// Server Functions
import axios from 'axios';
import * as libCon from '../Constants.js';


export const getUpdatedWearableCoverage = async (email) => {

    let emailString = `${email}`.replace('@gmail.com', '')
    let link = `${libCon.WEARABLE_COVERAGE_URL}/${emailString}`


    try {
        const response = await axios.get(link);

        if (response.status === 200) {
            let data = response.data.data

            let internalId = Object.keys(data)[0]

            data[internalId] = { ...data[internalId], [libCon.ID]: internalId, [libCon.KEY]: internalId }

            return ([libCon.OK, data])

        }
        else
            return ([libCon.ERROR, null])


    } catch (error) {

        console.log(error)
        if (error.message === 'Network request failed')
            return ([libCon.NETWORK_ERROR, null])

        return ([libCon.ERROR, null])

    }


}
