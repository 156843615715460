import { Progress, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import * as libCon from "../Constants.js"
import { getFinalColorFromWearableCoverage, getFinalPercentageFromWearableCoverage, getFinalTextFromWearableCoverage } from '../utils/devicesStatusFunctions.js'
import { formatDistanceShort, formatToIndiaTime, getDaysBetweenDates } from '../utils/dateFunctions.js';
import { isNullOrUndefined } from '../utils/generalFunctions.js';
import { subDays } from 'date-fns';


const { Text } = Typography;

// Translations Dictionary
const STATIC_TRANSLATIONS = {
    "overall_data_completeness": "Overall Data Completeness",
    "range": "Range",
    "night": "Night",
    "morning": "Morning",
    "afternoon": "Afternoon",
    "evening": "Evening",
    "please_refresh": "Please Refresh",
    "placement_just_started": "Placement just started. No coverage yet."

}


function CustomCircularProgressBar({ percentage, updatingEmail, fontSize = "14px", size = "small", disabledMessage = null, translations = (k) => k in STATIC_TRANSLATIONS ? STATIC_TRANSLATIONS[k] : k }) {

    return (
        <div>
            <div style={{ color: "black", marginBottom: "0px", padding: "0px" }}>
                <Progress
                    type="dashboard"
                    steps={{
                        count: 20,
                        gap: 1,
                    }}
                    percent={updatingEmail === true || !isNullOrUndefined(disabledMessage) ? 0 : percentage}
                    trailColor="rgba(0, 0, 0, 0.06)"
                    strokeWidth={20}
                    showInfo={updatingEmail === true || !isNullOrUndefined(disabledMessage) ? false : true}
                    size={size}
                    strokeColor={getFinalColorFromWearableCoverage(percentage)}
                />
            </div>
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <Text type="primary" style={{ fontSize: fontSize, marginTop: "0px" }}>{updatingEmail === true || !isNullOrUndefined(disabledMessage) ? "----" : translations(getFinalTextFromWearableCoverage(percentage))}</Text>
            </div>
        </div>
    )


}

function WearableCoverageViewer({ participant, email, updatingEmail, translations = (k) => k in STATIC_TRANSLATIONS ? STATIC_TRANSLATIONS[k] : k, externalDisabledMessage = null }) {

    // Disabled
    // Disabled message of just started
    const [disabledMessage, setDisabledMessage] = useState(externalDisabledMessage)

    useEffect(() => {

        // Priority to external message
        if (!isNullOrUndefined(externalDisabledMessage)) {
            setDisabledMessage(externalDisabledMessage)
            return
        }

        // Joined Date
        let joinedDate = participant[libCon.ATF_JOINED_DATE]
        let diffDaysSinceJoined = getDaysBetweenDates(joinedDate, new Date())

        // Difference in latest Upload and Record
        let latestUpload = email[libCon.ATF_LAST_UPLOADED]
        let latestRecord = email[libCon.ATF_LATEST_WEARABLE_COVERAGE_RECORD]


        if (diffDaysSinceJoined < libCon.WEARABLE_COVERAGE_BACK_DAYS)
            setDisabledMessage(translations("placement_just_started"))
        else if (isNullOrUndefined(latestUpload) || isNullOrUndefined(latestRecord) || getDaysBetweenDates(latestRecord, latestUpload) > 2)
            setDisabledMessage(translations("please_refresh"))
        else
            setDisabledMessage(null)


    }, [participant, email, translations, externalDisabledMessage])



    // Values
    const [total, setTotal] = useState(0)
    const [morning, setMorning] = useState(0)
    const [afternoon, setAfternoon] = useState(0)
    const [evening, setEvening] = useState(0)
    const [night, setNight] = useState(0)

    const [startDateString, setStartDateString] = useState("---")
    const [endDateString, setEndDateString] = useState("---")


    useEffect(() => {
        setTotal(getFinalPercentageFromWearableCoverage(email[libCon.ATF_WEARABLE_TOTAL_COVERAGE]))
        setMorning(getFinalPercentageFromWearableCoverage(email[libCon.ATF_WEARABLE_MORNING_COVERAGE]))
        setAfternoon(getFinalPercentageFromWearableCoverage(email[libCon.ATF_WEARABLE_AFTERNOON_COVERAGE]))
        setEvening(getFinalPercentageFromWearableCoverage(email[libCon.ATF_WEARABLE_EVENING_COVERAGE]))
        setNight(getFinalPercentageFromWearableCoverage(email[libCon.ATF_WEARABLE_NIGHT_COVERAGE]))

        if (!isNullOrUndefined(email[libCon.ATF_LATEST_WEARABLE_COVERAGE_RECORD])) {
            let d = new Date(email[libCon.ATF_LATEST_WEARABLE_COVERAGE_RECORD])

            let newEndDate = `${formatToIndiaTime(d)} (${formatDistanceShort(d)})`
            d = subDays(d, libCon.WEARABLE_COVERAGE_BACK_DAYS - 1)
            let newStartDate = `${formatToIndiaTime(d)} (${formatDistanceShort(d)})`

            setStartDateString(newStartDate)
            setEndDateString(newEndDate)
        }
        else {
            setStartDateString("---")
            setEndDateString("---")
        }

    }, [email, translations])


    const [updatingEmailProgress, setUpdatingEmailProgress] = useState(0)

    useEffect(() => {

        if (updatingEmail) {
            setUpdatingEmailProgress(0)
            const interval = setInterval(() => {
                setUpdatingEmailProgress((oldValue) => {
                    if (oldValue >= 100) {
                        return 100
                    } else {
                        return oldValue + (100 - oldValue) / 25
                    }
                })
            }, 1000)
            return () => clearInterval(interval)
        }
        else {
            setUpdatingEmailProgress(100)
            setTimeout(() => {
                setUpdatingEmailProgress(0)
            }, 2500);
        }


    }, [updatingEmail])


    return (
        <div className='verticalSection'>
            {
                updatingEmail || updatingEmailProgress > 0 ?
                    <Progress percent={updatingEmailProgress} showInfo={false} style={{ paddingLeft: "10px", paddingRight: "10px" }} />
                    : <div style={{ minHeight: "22px" }}></div>
            }

            {
                disabledMessage !== null && updatingEmail !== true ?
                    <div className='verticalSection'>
                        <Text type="danger" style={{ fontSize: "18px", textAlign: "center", wordWrap: "break-word", overflowWrap: "break-word" }}>{disabledMessage}</Text></div>
                    : <div style={{ minHeight: "10px" }}></div>
            }

            <div className='verticalSection'  >
                <h3 style={{ color: "black", marginTop: "5px", marginBottom: "0px" }}>{translations("overall_data_completeness")}</h3>
                <h4 style={{ color: "black", marginTop: "0px", marginBottom: "2px" }}>{translations("range")}</h4>
                <p style={{ margin: "1px" }}>
                    <strong>From: </strong>{updatingEmail === true || !isNullOrUndefined(disabledMessage) ? "---" : startDateString}
                </p>
                <p style={{ margin: "1px" }}>
                    <strong>To: </strong>{updatingEmail === true || !isNullOrUndefined(disabledMessage) ? "---" : endDateString}

                </p>
                <CustomCircularProgressBar translations={translations} percentage={total} updatingEmail={updatingEmail} disabledMessage={disabledMessage} fontSize='20px' size='medium' />
            </div>

            <div className='horizontalSection' style={{ justifyContent: "space-evenly" }}>
                {/* Night */}
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <h4 style={{ color: "black", marginTop: "5px", marginBottom: "2px" }}>{translations("night")}</h4>
                    <h4 style={{ color: "black", marginTop: "0px", marginBottom: "2px", padding: "0px" }}>{`00:00-6:00`}</h4>
                    <CustomCircularProgressBar translations={translations} percentage={night} updatingEmail={updatingEmail} disabledMessage={disabledMessage} />
                </div>
                {/* Morning */}
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <h4 style={{ color: "black", marginTop: "5px", marginBottom: "0px", padding: "0px" }}>{translations(`morning`)}</h4>
                    <h4 style={{ color: "black", marginTop: "0px", marginBottom: "2px", padding: "0px" }}>{`6:00-12:00`}</h4>
                    <CustomCircularProgressBar translations={translations} percentage={morning} updatingEmail={updatingEmail} disabledMessage={disabledMessage} />
                </div>

                {/* Afternoon */}
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <h4 style={{ color: "black", marginTop: "5px", marginBottom: "0px", padding: "0px" }}>{translations("afternoon")}</h4>
                    <h4 style={{ color: "black", marginTop: "0px", marginBottom: "2px", padding: "0px" }}>{`12:00-18:00`}</h4>
                    <CustomCircularProgressBar translations={translations} percentage={afternoon} updatingEmail={updatingEmail} disabledMessage={disabledMessage} />
                </div>

                {/* Evening */}
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <h4 style={{ color: "black", marginTop: "5px", marginBottom: "2px" }}>{translations("evening")}</h4>
                    <h4 style={{ color: "black", marginTop: "0px", marginBottom: "2px", padding: "0px" }}>{`18:00-00:00`}</h4>
                    <CustomCircularProgressBar translations={translations} percentage={evening} updatingEmail={updatingEmail} disabledMessage={disabledMessage} />
                </div>


            </div>

        </div>
    )
}

export default WearableCoverageViewer