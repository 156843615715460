// Sensor Functions
import * as libCon from "../Constants.js"

export const getExpiryDaysByBrand = (brand) => {
    switch (brand) {
        case libCon.GOVEE:
            return libCon.GOVEE_CAPACITY
        case libCon.KESTREL:
            return libCon.KESTREL_CAPACITY
        case libCon.HOBO:
            return libCon.HOBO_CAPACITY
        default:
            return 20;
    }

}