import { PDFDocument, rgb } from 'pdf-lib';
import * as libCon from '../Constants.js';
// import { getDocument } from 'pdfjs-dist';
import * as pdfjsLib from 'pdfjs-dist/webpack.mjs';
import { getAllIndexes, isNullOrUndefined, isValidDate } from './generalFunctions.js';
import { parse } from 'date-fns';
import JSZip from "jszip";
import jaroWinkler from 'jaro-winkler';
import levenshtein from "fast-levenshtein"

function normalizeNumber(phone) {
    return phone.replace(/\D/g, ""); // Remove all non-digit characters
}

export function phoneMatchScore(num1, num2) {
    const n1 = normalizeNumber(num1);
    const n2 = normalizeNumber(num2);
    const maxLength = Math.max(n1.length, n2.length);

    if (maxLength === 0) return 1;

    const distance = levenshtein.get(n1, n2);
    return ((maxLength - distance) / maxLength);
}

export function ageDiff(age1, age2) {
    if (isNullOrUndefined(age1) || isNullOrUndefined(age2))
        return null

    return Math.abs(age1 - age2);

}

export const ageScoreFromDiff = (diff) => {
    if (isNullOrUndefined(diff))
        return 0

    return Math.max(0, 1 - diff * 0.05)
}

export function namesMatchScore(name1, name2) {

    name1 = name1.toLowerCase().replace("ben", "")
    name2 = name2.toLowerCase().replace("ben", "")
    return jaroWinkler(name1, name2);

}

export function overallScore(meta) {
    let score = 0.7 * meta[libCon.ATF_NAME_SCORE] + 0.2 * meta[libCon.ATF_PHONE_SCORE] + 0.1 * ageScoreFromDiff(meta[libCon.ATF_AGE_DIFF])
    return Math.round(score * 100) / 100;
}

export function getMatches(metadata, participants) {


    let processedArray = Object.values(participants).map(p => {

        let values = {}

        // General Info
        values[libCon.ATF_TARGET_COMPLETE_NAME] = `${p[libCon.ATF_NAME]} ${p[libCon.ATF_LAST_NAME]}`
        values[libCon.ATF_TARGET_SEWA_ID] = p[libCon.ATF_SEWA_ID]

        // Target Number
        values[libCon.ATF_TARGET_PHONE_NUMBER] = null
        if (libCon.ATF_PHONE_NUMBER in p)
            values[libCon.ATF_TARGET_PHONE_NUMBER] = p[libCon.ATF_PHONE_NUMBER].replace(/[()\-\s]/g, '');

        // Checks match
        values[libCon.ATF_PHONE_SCORE] = 0
        if (!isNullOrUndefined(metadata[libCon.ATF_PHONE_NUMBER]) && !isNullOrUndefined(values[libCon.ATF_TARGET_PHONE_NUMBER]) && values[libCon.ATF_TARGET_PHONE_NUMBER] !== "0000000000")
            values[libCon.ATF_PHONE_SCORE] = phoneMatchScore(`${values[libCon.ATF_TARGET_PHONE_NUMBER]}`, `${metadata[libCon.ATF_PHONE_NUMBER]}`)


        // Target Age
        values[libCon.ATF_TARGET_AGE] = null
        if (libCon.ATF_AGE in p)
            values[libCon.ATF_TARGET_AGE] = p[libCon.ATF_AGE]

        values[libCon.ATF_AGE_DIFF] = null


        if (!isNullOrUndefined(metadata[libCon.ATF_AGE]) && !isNullOrUndefined(values[libCon.ATF_TARGET_AGE]))
            values[libCon.ATF_AGE_DIFF] = ageDiff(values[libCon.ATF_TARGET_AGE], metadata[libCon.ATF_AGE])


        // Score
        let score = 0
        if (!isNullOrUndefined(metadata[libCon.ATF_NAME]) && !isNullOrUndefined(values[libCon.ATF_TARGET_COMPLETE_NAME]))
            score = namesMatchScore(values[libCon.ATF_TARGET_COMPLETE_NAME], metadata[libCon.ATF_NAME])

        values[libCon.ATF_NAME_SCORE] = Math.round(score * 100) / 100;

        // Confident Mathch
        values[libCon.ATF_SCORE] = overallScore(values)

        return values

    })

    processedArray = processedArray.sort((a, b) => b[libCon.ATF_SCORE] - a[libCon.ATF_SCORE]);

    // Adds second best score
    processedArray = processedArray.map((p, i) => {
        return ({
            ...p, [libCon.ATF_SCORE_SECOND_BEST]: i + 1 >= processedArray.length ? null : processedArray[i + 1][libCon.ATF_SCORE],
            [libCon.ATF_NAME_SCORE_SECOND_BEST]: i + 1 >= processedArray.length ? null : processedArray[i + 1][libCon.ATF_NAME_SCORE],
            [libCon.ATF_NAME_SECOND_BEST]: i + 1 >= processedArray.length ? null : processedArray[i + 1][libCon.ATF_TARGET_COMPLETE_NAME],
            [libCon.ATF_PHONE_SCORE_SECOND_BEST]: i + 1 >= processedArray.length ? null : processedArray[i + 1][libCon.ATF_PHONE_SCORE],
            [libCon.ATF_AGE_DIFF_SECOND_BEST]: i + 1 >= processedArray.length ? null : processedArray[i + 1][libCon.ATF_AGE_DIFF],
        })
    })
    return processedArray
}


export async function extractPDFsFromZip(zipFileBuffer, setProcessProgress = (i) => true) {


    const zip = await JSZip.loadAsync(zipFileBuffer); // Load the ZIP file
    const pdfFiles = [];
    const metadataArray = []
    const errorFiles = [];

    // Iterate through all files in the ZIP
    let i = 0
    for (const fileName of Object.keys(zip.files)) {
        try {
            if (fileName.endsWith("/"))
                continue

            const pdfBlob = await zip.files[fileName].async("arraybuffer"); // Convert to Blob
            const pdfBytes = new Uint8Array(pdfBlob);
            let metadata = extractMetadata(await extractAllText(new Uint8Array(pdfBytes)));

            metadata[libCon.ATF_FILE_NAME] = fileName.split("/").pop();

            pdfFiles.push(new Uint8Array(pdfBytes));
            metadataArray.push(metadata);

        } catch (error) {
            errorFiles.push(fileName.split("/").pop());
        }

        i += 1
        setProcessProgress(100 * i / Object.keys(zip.files).length)

    }

    return [pdfFiles, metadataArray, errorFiles];


}


export async function splitPdf(pdfBytes) {

    const pdfSize = 3

    const originalPdf = await PDFDocument.load(pdfBytes);

    const pdfs = [];

    let bufferPfs = [];

    let newPdfBytes, newPage

    let currentPDF = await PDFDocument.create();

    for (let i = 0; i < originalPdf.getPageCount(); i++) {

        if (i % pdfSize === 0 && bufferPfs.length > 0) {

            newPdfBytes = await currentPDF.save();
            pdfs.push(newPdfBytes); // Each PDF file as Uint8Array

            bufferPfs = []
            currentPDF = await PDFDocument.create();

        }

        [newPage] = await currentPDF.copyPages(originalPdf, [i]);
        currentPDF.addPage(newPage)
        bufferPfs.push(newPage)


    }

    // Final Document
    if (bufferPfs.length > 0) {
        newPdfBytes = await currentPDF.save();
        pdfs.push(newPdfBytes); // Each PDF file as Uint8Array
    }


    return pdfs; // Array of PDFs split by page
}

export async function extractAllText(pdfBytes) {
    try {
        // Load the PDF document
        const pdfDocument = await pdfjsLib.getDocument({ data: pdfBytes }).promise;

        let fullText = [];

        // Loop through each page
        for (let i = 1; i <= pdfDocument.numPages; i++) {
            const page = await pdfDocument.getPage(i);
            const textContent = await page.getTextContent();

            // Extract text from the page
            const pageText = textContent.items
                .map((item) => item.str);

            fullText = fullText.concat(pageText); // Add a newline after each page
        }

        // Cleans
        fullText = fullText.map(p => p.replace(" ", ""))
        fullText = fullText.filter(p => p !== "")



        return fullText;
    } catch (error) {
        console.error('Error extracting text from PDF:', error);
        throw error;
    }
}

export function extractMetadata(textArray) {


    let i = -1;
    let response = {}

    // Name
    let name = null
    i = textArray.indexOf("Name")
    if (i >= 0) {
        // Colon
        if (i + 1 < textArray.length && textArray[i + 1] === ":")
            i += 1

        i += 1
        name = ""
        while (i < textArray.length && textArray[i] !== "Sex/Age") {
            name = `${name} ${textArray[i]}`
            i += 1
        }
        name = name.trim()


    }
    response[libCon.ATF_NAME] = name


    // CaseId
    let caseId = null
    i = textArray.indexOf("CaseID")
    if (i >= 0) {
        // Colon
        if (i + 1 < textArray.length && textArray[i + 1] === ":")
            i += 1

        if (i + 1 < textArray.length)
            caseId = textArray[i + 1]
    }

    response[libCon.ATF_CASE_ID] = caseId


    // Age
    let age = null
    i = textArray.indexOf("Sex/Age")
    if (i >= 0) {
        i += 3
        if (i + 1 < textArray.length) {
            age = textArray[i + 1]
            age = parseInt(age.replace("Years", ''))

            if (isNullOrUndefined(age))
                age = null
        }

    }
    response[libCon.ATF_AGE] = age


    // Phone Number
    let phoneNumber = null
    let indices = getAllIndexes(textArray.map(t => t.replace(" ", "")), "MobileNo.")
    // Value might be in second or third page
    for (let j = 0; j < indices.length; j++) {

        let i = indices[j]
        // Colon
        if (i + 1 < textArray.length && textArray[i + 1] === ":")
            i += 1

        if (i + 1 < textArray.length) {
            let parsedPhoneNumber = parseInt(textArray[i + 1])
            if (!isNullOrUndefined(parsedPhoneNumber) && `${parsedPhoneNumber}`.length >= 10) {
                phoneNumber = parsedPhoneNumber
                break
            }


        }
    }
    response[libCon.ATF_PHONE_NUMBER] = phoneNumber

    // Sample Date
    let sampleDate = null
    indices = getAllIndexes(textArray, "RefId2")

    // Value might be in second or third page
    for (let j = 0; j < indices.length; j++) {

        let i = indices[j]
        // Colon
        if (i + 1 < textArray.length && textArray[i + 1] === ":")
            i += 1

        if (i + 1 < textArray.length) {
            sampleDate = parse(textArray[i + 1], "dd-MMM-yyyyHH:mm", new Date());
        }

        if (isValidDate(sampleDate))
            break

    }
    response[libCon.ATF_SAMPLE_DATE] = sampleDate

    return response
}


export async function processPdf(pdfBytes, replacement) {

    // Step 2: Modify the PDF with pdf-lib
    const pdfDoc = await PDFDocument.load(pdfBytes);
    const pages = pdfDoc.getPages();

    pages.forEach((page) => {

        // Name
        // Rectangle
        page.drawRectangle({
            x: 96,
            y: 720,
            width: 174,
            height: 14,
            color: rgb(1, 1, 1), // White color
        });
        // Overlay text
        page.drawText(libCon.REPORT_NAME_MASK, {
            x: 100,
            y: 723,
            size: 13, // Adjust the font size as needed
            color: rgb(1, 1, 1), // Black color
        });

        page.drawText(replacement.toString(), {
            x: 100,
            y: 723,
            size: 13, // Adjust the font size as needed
            color: rgb(0, 0, 0), // Black color
        });

        // Phone Number
        page.drawRectangle({
            x: 502,
            y: 673,
            width: 80,
            height: 14,
            color: rgb(1, 1, 1), // White color
        });
        // Overlay text
        page.drawText(libCon.REPORT_PHONE_MASK, {
            x: 502,
            y: 676,
            size: 13, // Adjust the font size as needed
            color: rgb(1, 1, 1), // Black color
        });

    });

    // Step 3: Save the modified PDF
    const modifiedPdfBytes = await pdfDoc.save();
    return modifiedPdfBytes;
}


export function downloadZip(pdfs, fileNames, zipFileName = "files.zip") {
    if (pdfs.length !== fileNames.length) {
        console.error("PDFs array and file names array must have the same length.");
        return;
    }

    const zip = new JSZip();

    pdfs.forEach((pdf, index) => {
        zip.file(fileNames[index], pdf, { binary: true });
    });

    zip.generateAsync({ type: "blob" }).then((zipBlob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(zipBlob);
        link.download = zipFileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }).catch(err => console.error("Error generating ZIP:", err));
}