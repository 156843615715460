
import { notification } from "antd";
import * as libCon from "../community-hats-js-library/Constants.js";

export const showNotification = (message) => {
    notification.open({
        message: message,
        showProgress: true,
        pauseOnHover: true,
        placement: "top",
        duration: 6,
        style: {
            background: "var(--background-color-1-transparent)"
        }
    })

}

export const showError = (message) => {
    notification.open({
        message: message,
        showProgress: true,
        pauseOnHover: true,
        placement: "top",
        style: {
            background: "var(--primary-color-2-transparent)"
        }
    })


}

export const getSensorBrandColor = (brand) => {
    switch (brand) {
        case libCon.GOVEE:
            return ("var(--chart-color-1)");
        case libCon.KESTREL:
            return ("var(--chart-color-6)");
        case libCon.HOBO:
            return ("var(--chart-color-8)");
        default:
            return ("var(--chart-color-9)");

    }
}