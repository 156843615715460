
// General store actions
import { store } from '../store';
import * as libCon from "../../community-hats-js-library/Constants.js"
import * as locCon from "../../LocalConstants"

export const setValue = (id, val) => {
    store.dispatch({
        type: locCon.SET_VALUE,
        payload: { [libCon.ID]: id, [libCon.VALUE]: val }
    })

}

export const getValue = (id) => {
    return store.getState()[id]

}


export const updateObjectValue = (id, newVal) => {
    store.dispatch({
        type: locCon.UPDATE_OBJECT_VALUE,
        payload: { [libCon.ID]: id, [libCon.VALUE]: newVal }
    })

}


export const resetAllDatasets = () => {
    store.dispatch({
        type: locCon.RESET_ALL_DATASETS,
        payload: {}
    })

}
