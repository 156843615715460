import React, { useState } from 'react';
import Title from 'antd/es/typography/Title';
import { getMatches } from '../community-hats-js-library/utils/labFunctions';

import { Button, Form, Input, Space, Table, } from "antd";
import { RefParticipantsData } from '../hooks/DatasetHooks';
import * as libCon from "../community-hats-js-library/Constants.js"
import { isNullOrUndefined, stringIsNumeric } from '../community-hats-js-library/utils/generalFunctions.js';
import { LoadingParticipants } from '../components/LoadingDatasets';


const GENERAL_COLUMNS = [
  {
    title: libCon.ATF_SCORE,
    dataIndex: libCon.ATF_SCORE,
    sorter: (a, b) => a[libCon.ATF_SCORE] - b[libCon.ATF_SCORE],
    align: 'center',
    width: 100,
  },
  {
    title: libCon.ATF_TARGET_COMPLETE_NAME,
    dataIndex: libCon.ATF_TARGET_COMPLETE_NAME,
    align: 'center',
    width: 100,
  },
  {
    title: libCon.ATF_TARGET_SEWA_ID,
    dataIndex: libCon.ATF_TARGET_SEWA_ID,
    align: 'center',
    width: 100,
  },
  {
    title: libCon.ATF_NAME_SCORE,
    dataIndex: libCon.ATF_NAME_SCORE,
    sorter: (a, b) => a[libCon.ATF_NAME_SCORE] - b[libCon.ATF_NAME_SCORE],
    align: 'center',
    width: 100,
  },
  {
    title: libCon.ATF_TARGET_PHONE_NUMBER,
    dataIndex: libCon.ATF_TARGET_PHONE_NUMBER,
    align: 'center',
    width: 100,
  },
  {
    title: libCon.ATF_PHONE_SCORE,
    dataIndex: libCon.ATF_PHONE_SCORE,
    sorter: (a, b) => a[libCon.ATF_PHONE_SCORE] - b[libCon.ATF_PHONE_SCORE],
    align: 'center',
    width: 100,
  },

  {
    title: libCon.ATF_TARGET_AGE,
    dataIndex: libCon.ATF_TARGET_AGE,
    align: 'center',
    width: 100,
  },
  {
    title: libCon.ATF_AGE_DIFF,
    dataIndex: libCon.ATF_AGE_DIFF,
    sorter: (a, b) => a[libCon.ATF_AGE_DIFF] - b[libCon.ATF_AGE_DIFF],
    align: 'center',
    width: 100,
  }

]

const formItemStyle = {
  textAlign: 'center', // Center label text
  color: 'white', // Make label white
  fontWeight: 'bold',
  fontSize: '16px',
};

function DashboardNameMatcher() {

  const [name, setName] = useState(null)
  const [phone, setPhone] = useState(null)
  const [age, setAge] = useState(null)

  const [closestMatches, setClosestMatches] = useState([])

  const [participantStatus, participants] = RefParticipantsData()


  const showResults = async () => {

    setClosestMatches([])


    if (isNullOrUndefined(name) && isNullOrUndefined(phone) && isNullOrUndefined(age)) {
      setClosestMatches([])
      return
    }

    let numericAge = null
    if (stringIsNumeric(age))
      numericAge = parseInt(age)

    let metadata = { [libCon.ATF_NAME]: name, [libCon.ATF_PHONE_NUMBER]: phone, [libCon.ATF_AGE]: numericAge }

    let sortedMatches = getMatches(metadata, participants)

    sortedMatches = sortedMatches.map(ob => { return ({ ...metadata, ...ob }) })

    setClosestMatches(sortedMatches)

  }

  return (
    <div className="mainContainer" >
      <Title level={3}>{`Match Checker`}</Title>
      {
        participantStatus !== libCon.OK
          ? <LoadingParticipants />
          : <div >
            <Space direction="horizontal" >

              <Form layout="vertical">
                <Form.Item label={<span style={formItemStyle}>Name</span>}>
                  <Input placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                </Form.Item>
              </Form>
              <Form layout="vertical">
                <Form.Item label={<span style={formItemStyle}>Age</span>}>
                  <Input placeholder="Age" value={age} onChange={(e) => setAge(e.target.value)} />
                </Form.Item>
              </Form>
              <Form layout="vertical">
                <Form.Item label={<span style={formItemStyle}>Phone Number</span>}>
                  <Input placeholder="Phone Number" value={phone} onChange={(e) => setPhone(e.target.value)} />
                </Form.Item>
              </Form>
              <Button type="primary" onClick={() => showResults()}>Search</Button>
              <Button type="primary" onClick={() => { setName(null); setAge(null); setPhone(null) }} danger>Clear</Button>
            </Space>

            <div style={{ maxWidth: "98vw", marginTop: "20px" }}>
              {
                closestMatches.length > 0
                  ? <Table dataSource={closestMatches} columns={GENERAL_COLUMNS} size="small" scroll={{ x: 'max-content', y: window.innerHeight * 0.65 }} style={{ fontSize: '12px' }} />
                  : <div></div>
              }
            </div>

          </div>
      }

    </div>
  )

}


export default DashboardNameMatcher