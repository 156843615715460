import React, { useEffect, useState } from 'react'
import { RefActiveParticipantsWithAllPlacements, refreshAllDatasets } from '../hooks/DatasetHooks'
import * as libCon from "../community-hats-js-library/Constants.js"
import * as locCon from "../LocalConstants"
import { Button, Input, Radio, Space, Table } from "antd";
import { Typography } from 'antd';
import { getColorOfExpiry, getColorOfLastUpload, getColorOfLostDays, getTextOfExpiry, isNullOrUndefined, isNullOrUndefinedOrEmpty } from '../community-hats-js-library/utils/generalFunctions.js';
import { formatDistanceGivenDays, formatDistanceShort } from '../community-hats-js-library/utils/dateFunctions.js';
import { SymbolForStatus } from '../community-hats-js-library/utils/devicesStatusFunctions';
import { ArrowRightOutlined } from '@ant-design/icons';
import { setValue } from '../store/actions/generalStoreActions';
import { RefSetCurrentPage } from '../hooks/NavigationHooks';
import LoadingDatasets from '../components/LoadingDatasets';
import { getStoredValue, RefStoredValue, setStoredValue, StateStoredValue } from '../hooks/GeneralStoreHooks';

const { Text, Title } = Typography;


function FilterComponent() {


  useEffect(() => {
    setStoredValue(locCon.FILTER_TEXT, null)

  }, [])


  const [inputValue, setInputValue] = useState(() => getStoredValue(locCon.FILTER_TEXT))

  return (
    <Space direction="horizontal" >
      <Button type="primary" onClick={() => refreshAllDatasets()}>Refresh All</Button>
      <Input placeholder="Filter" value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
      <Button type="primary" onClick={() => setStoredValue(locCon.FILTER_TEXT, inputValue)}>Filter</Button>
      <Button type="primary" onClick={() => { setInputValue(null); setStoredValue(locCon.FILTER_TEXT, null) }} danger>Clear</Button>
    </Space>
  )
}
const generalColumns = [
  {
    title: <p>Collection<br />Status</p>,
    dataIndex: libCon.AT_COLLECTION_STATUS,
    key: 1,
    align: 'center',
    width: 100,
    sorter: (a, b) => a[libCon.AT_COLLECTION_STATUS].localeCompare(b[libCon.AT_COLLECTION_STATUS]),
    render: (val, p) => <SymbolForStatus status={p[libCon.AT_COLLECTION_STATUS]} message={p[libCon.AT_COLLECTION_MESSAGE]} />
  },
  {
    title: <p>Area</p>,
    dataIndex: libCon.ATF_AREA,
    key: 1,
    align: 'center',
    width: 100,
  },
  {
    title: 'Names',
    dataIndex: libCon.ATF_NAME,
    key: 2,
    align: 'center',
    width: 100,
    fixed: "left",
    render: (val, record) => <p style={{ textAlign: 'center', color: getColorOfExpiry(record[libCon.ATF_DAYS_UNTIL_EXPIRE]) }}>{record[libCon.ATF_NAME] + " " + record[libCon.ATF_LAST_NAME]}</p>
  },
  {
    title: ' SEWA Id ',
    dataIndex: libCon.ATF_SEWA_ID,
    key: 3,
    align: 'center',
    width: 100,
    render: (val, record) => <p style={{ fontSize: 12, textAlign: 'center', color: getColorOfExpiry(record[libCon.ATF_DAYS_UNTIL_EXPIRE]) }}>{val}</p>
  },
  {
    title: 'Address',
    dataIndex: libCon.ATF_ADDRESS,
    key: 4,
    align: 'center',
    width: 100,
    render: (val, record) => <p style={{ fontSize: 12, textAlign: 'center', color: getColorOfExpiry(record[libCon.ATF_DAYS_UNTIL_EXPIRE]) }}>{val}</p>
  },
  {
    title: <p>Next Collection</p>,
    dataIndex: libCon.ATF_DAYS_UNTIL_EXPIRE,
    key: 5,
    align: 'center',
    width: 100,
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a[libCon.ATF_DAYS_UNTIL_EXPIRE] - b[libCon.ATF_DAYS_UNTIL_EXPIRE],
    render: (val, record) => <p style={{ textAlign: 'center', color: getColorOfExpiry(record[libCon.ATF_DAYS_UNTIL_EXPIRE]) }}>{getTextOfExpiry(val)}</p>

  },
  {
    title: <p>Last Collection</p>,
    dataIndex: libCon.ATF_LATEST_COLLECTION_DATE,
    key: 6,
    align: 'center',
    width: 100,
    sorter: (a, b) => a[libCon.ATF_DAYS_SINCE_LAST_HOUSE_VISIT] - b[libCon.ATF_DAYS_SINCE_LAST_HOUSE_VISIT],
    render: (val, record) => <p style={{ textAlign: 'center', color: getColorOfLastUpload(record[libCon.ATF_DAYS_SINCE_LAST_HOUSE_VISIT]) }}>{formatDistanceShort(val)}</p>

  },
  {
    title: <p>Lost Data</p>,
    dataIndex: libCon.ATF_DAYS_UNTIL_EXPIRE,
    key: 7,
    align: 'center',
    width: 100,
    sorter: (a, b) => a[libCon.ATF_DAYS_UNTIL_EXPIRE] - b[libCon.ATF_DAYS_UNTIL_EXPIRE],
    render: (val, record) => <p style={{ textAlign: 'center', color: getColorOfLostDays(record[libCon.ATF_DAYS_UNTIL_EXPIRE]) }}>{val >= 0 ? "0 days" : formatDistanceGivenDays(val, false)}</p>

  },
  {
    title: <p>Sensor<br />Status</p>,
    dataIndex: libCon.ATF_HAS_ACTIVE_SENSORS,
    key: 8,
    align: 'center',
    width: 100,
    sorter: (a, b) => a[libCon.AT_SENSORS_COLLECTION_STATUS].localeCompare(b[libCon.AT_SENSORS_COLLECTION_STATUS]),
    render: (val, record) => <SymbolForStatus status={record[libCon.AT_SENSORS_COLLECTION_STATUS]} message={record[libCon.AT_SENSORS_COLLECTION_MESSAGE]} />

  },
  {
    title: <p>Location Tracker<br />Status</p>,
    dataIndex: libCon.ATF_HAS_ACTIVE_PHONES,
    key: 9,
    align: 'center',
    width: 80,
    sorter: (a, b) => a[libCon.ATF_ACTIVE_PHONE][libCon.ATF_DEVICE_COLLECTION_STATUS].localeCompare(b[libCon.ATF_ACTIVE_PHONE][libCon.ATF_DEVICE_COLLECTION_STATUS]),
    render: (val, record) => <SymbolForStatus status={record[libCon.ATF_ACTIVE_PHONE][libCon.ATF_DEVICE_COLLECTION_STATUS]} message={record[libCon.ATF_ACTIVE_PHONE][libCon.ATF_DEVICE_COLLECTION_MESSAGE]} />

  },
  {
    title: <p>Wearable<br />Status</p>,
    dataIndex: libCon.ATF_HAS_ACTIVE_EMAILS,
    key: 10,
    align: 'center',
    width: 80,
    sorter: (a, b) => a[libCon.ATF_ACTIVE_EMAIL][libCon.ATF_DEVICE_COLLECTION_STATUS].localeCompare(b[libCon.ATF_ACTIVE_EMAIL][libCon.ATF_DEVICE_COLLECTION_STATUS]),
    render: (val, record) => <SymbolForStatus status={record[libCon.ATF_ACTIVE_EMAIL][libCon.ATF_DEVICE_COLLECTION_STATUS]} message={record[libCon.ATF_ACTIVE_EMAIL][libCon.ATF_DEVICE_COLLECTION_MESSAGE]} />

  },
  {
    title: <p>Perceptual<br />Survey<br />Status</p>,
    dataIndex: libCon.ATF_PERCEPTUAL_SURVEY,
    key: 11,
    align: 'center',
    width: 100,
    sorter: (a, b) => a[libCon.ATF_PERCEPTUAL_SURVEY][libCon.ATF_DEVICE_COLLECTION_STATUS].localeCompare(b[libCon.ATF_PERCEPTUAL_SURVEY][libCon.ATF_DEVICE_COLLECTION_STATUS]),
    render: (val, record) => <SymbolForStatus status={record[libCon.ATF_PERCEPTUAL_SURVEY][libCon.ATF_DEVICE_COLLECTION_STATUS]} message={record[libCon.ATF_PERCEPTUAL_SURVEY][libCon.ATF_DEVICE_COLLECTION_MESSAGE]} />

  },
  {
    title: <p>Intake<br />From<br />Status</p>,
    dataIndex: libCon.ATF_ALL_INTAKE_FORMS,
    key: 12,
    align: 'center',
    width: 80,
    sorter: (a, b) => a[libCon.ATF_ALL_INTAKE_FORMS][libCon.ATF_DEVICE_COLLECTION_STATUS].localeCompare(b[libCon.ATF_ALL_INTAKE_FORMS][libCon.ATF_DEVICE_COLLECTION_STATUS]),
    render: (val, record) => <SymbolForStatus status={record[libCon.ATF_ALL_INTAKE_FORMS][libCon.ATF_DEVICE_COLLECTION_STATUS]} message={record[libCon.ATF_ALL_INTAKE_FORMS][libCon.ATF_DEVICE_COLLECTION_MESSAGE]} />

  },
  {
    title: <p>Pending<br />Issues</p>,
    dataIndex: libCon.ATF_GROUND_TEAM_ISSUES,
    key: 13,
    align: 'center',
    width: 80,
    render: (val, record) => <p>{val === null || val === undefined ? 0 : val.filter(i => i[libCon.ATF_STATUS] === libCon.ATF_PENDING).length}</p>

  }
];


function DashboardExpiryParticipants() {

  // Navigation
  const setCurrentPage = RefSetCurrentPage()

  const [location, setLocation] = StateStoredValue(locCon.PROJECT_LOCATION)

  // Participants
  const [status, participants] = RefActiveParticipantsWithAllPlacements()

  const [participantArray, setParticipantArray] = useState([])

  const filterText = RefStoredValue(locCon.FILTER_TEXT)

  // Status
  const [isTableReady, setIsTableReady] = useState(false)

  useEffect(() => {

    setIsTableReady(status !== libCon.STATUS_NOT_STARTED && status !== libCon.LOADING && participants !== null)
  }, [status, participants])


  useEffect(() => {

    if (participants !== null) {
      let parArray = Object.values(participants)

      // Filter Text
      if (!isNullOrUndefinedOrEmpty(filterText))
        parArray = parArray.filter(ob => ob[libCon.ATF_PUBLIC_ID].toUpperCase().includes(filterText.toUpperCase()))


      // Filter Location 
      if (!isNullOrUndefined(location) && location !== libCon.ATF_ALL)
        parArray = parArray.filter(ob => libCon.ATF_AREA in ob && ob[libCon.ATF_AREA].map(s => s.toUpperCase()).includes(location.toUpperCase()))


      setParticipantArray(parArray)
    }

  }, [participants, filterText, location])

  const userType = RefStoredValue(locCon.USER_TYPE)

  const [columns, setColumns] = useState([])

  useEffect(() => {

    const goToParticipant = (id) => {
      setValue(locCon.SELECTED_PARTICIPANT, id)
      setCurrentPage(locCon.PAGE_DASHBOARD_SINGLE_COLLECTION_STATUS)
    }

    let localColumns = [...generalColumns,
    {
      title: 'Go To',
      dataIndex: libCon.ID,
      key: 14,
      align: 'center',
      width: 80,
      render: (val) => <Button type="primary" onClick={() => goToParticipant(val)}><ArrowRightOutlined /></Button>,
    },]

    if (!isNullOrUndefined(location) && location !== libCon.ATF_ALL) {
      localColumns = localColumns.filter(col => col.dataIndex !== libCon.ATF_AREA)
    }

    const sensitiveCols = [libCon.ATF_NAME, libCon.ATF_ADDRESS]

    if (userType !== locCon.USER_TYPE_SEWA)
      localColumns = localColumns.filter(col => !sensitiveCols.includes(col.dataIndex))

    setColumns(localColumns)

  }, [userType, setCurrentPage, location])



  return (
    <div className="mainContainer">
      <Title level={2}>{`Participant Data About to Expire`}</Title>
      <Space direction='vertical' style={{ marginBottom: "10px" }}>

        <Radio.Group onChange={(e) => setLocation(e.target.value)} value={location}>
          <Space direction="horizontal">
            <Radio style={{ color: "white", fontSize: "14px" }} value={null}>All</Radio>
            <Radio style={{ color: "white", fontSize: "14px" }} value={libCon.ATF_AHMEDABAD}>Ahmedabad</Radio>
            <Radio style={{ color: "white", fontSize: "14px" }} value={libCon.ATF_ANAND}>Anand</Radio>
            <Radio style={{ color: "white", fontSize: "14px" }} value={libCon.ATF_FIROZABAD}>Firozabad</Radio>
          </Space>
        </Radio.Group>
      </Space>

      <Space direction="vertical" >
        <FilterComponent />
        <Space direction="horizontal" size={"large"}>
          <Space direction="horizontal" >
            <SymbolForStatus size={26} status={libCon.DEVICE_STATUS_OK} message={libCon.MESSAGE_DATA_UP_TO_DATE} />
            <Text style={{ fontSize: "0.8em", color: "var(--text-color)" }}>{!isTableReady ? "--" : participantArray.filter(p => p[libCon.AT_COLLECTION_STATUS] === libCon.DEVICE_STATUS_OK).length}</Text>
          </Space>
          <Space direction="horizontal" >
            <SymbolForStatus size={26} status={libCon.DEVICE_STATUS_WAITING_COLLECTION} message={libCon.MESSAGE_WAITING_FOR_COLLECTION} />
            <Text style={{ fontSize: "0.8em", color: "var(--text-color)" }}>{!isTableReady ? "--" : participantArray.filter(p => p[libCon.AT_COLLECTION_STATUS] === libCon.DEVICE_STATUS_WAITING_COLLECTION).length}</Text>
          </Space>
          <Space direction="horizontal" >
            <SymbolForStatus size={26} status={libCon.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA} message={libCon.MESSAGE_WAITING_FOR_COLLECTION_LOOSING_DATA} />
            <Text style={{ fontSize: "0.8em", color: "var(--text-color)" }}>{!isTableReady ? "--" : participantArray.filter(p => p[libCon.AT_COLLECTION_STATUS] === libCon.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA).length}</Text>
          </Space>
          <Space direction="horizontal" >
            <SymbolForStatus size={26} status={libCon.DEVICE_STATUS_ERROR_IN_COLLECTION} message={libCon.MESSAGE_ERROR_IN_COLLECTION} />
            <Text style={{ fontSize: "0.8em", color: "var(--text-color)" }}>{!isTableReady ? "--" : participantArray.filter(p => p[libCon.AT_COLLECTION_STATUS] === libCon.DEVICE_STATUS_ERROR_IN_COLLECTION).length}</Text>
          </Space>
        </Space>

      </Space>

      <div style={{ maxWidth: "98vw", marginTop: "20px" }}>
        {
          !isTableReady
            ? <LoadingDatasets />
            : status === libCon.ERROR
              ? <div>Error</div>
              : <Table dataSource={participantArray} columns={columns} size="small" scroll={{ x: 'max-content', y: window.innerHeight * 0.65 }} pagination={{ pageSize: 50 }} />
        }
      </div>


    </div>
  )
}

export default DashboardExpiryParticipants